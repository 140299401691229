import React from 'react';
import { LiteTable } from '@roamingaudit/react-lite-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const OperatorsTable = props => {
  const actions = row => {
    const actions = [
      <Form.Switch
        id={ `operator-status-${ row.id }` }
        checked={ !!row.status }
        onChange={ () => props.toggleOperator(row.id, !row.status) }/>
    ];

    actions.push(<Button
      variant="primary"
      size="sm"
      title="Operator Details"
      onClick={ e => {
        e.stopPropagation();

        props.editOperator(row.id);
      } }>
      <FontAwesomeIcon icon={ faSearch }/>
    </Button>);

    if (props.current !== row.id) {
      actions.push(<Button
        variant="danger"
        size="sm"
        title="Delete Operator"
        onClick={ e => {
          e.stopPropagation();

          props.deleteOperator(row);
        } }>
        <FontAwesomeIcon icon={ faTrashAlt }/>
      </Button>);
    }

    return actions;
  }

  return <LiteTable
    cellWrapper={ (row, column, value) => {
      return props.current !== row.id && column === 'name'
        ? <Button variant="link" onClick={ e => {
          e.stopPropagation();

          props.useOperator(row.id, row.name);
        } }>{ value }</Button>
        : value;
    } }
    onFilter={ props.onFilter }
    withSorting
    withFiltering
    initialSorting={ {
      column: 'id',
      reversed: false
    } }
    columnsMap={ {
      name: 'Name',
      countryName: 'Country',
      numberOfUsers: 'Number of Users'
    } }
    rows={ props.rows }
    actions={ actions }/>
};

OperatorsTable.propTypes = {
  current: PropTypes.number.isRequired,
  useOperator: PropTypes.func.isRequired,
  viewOperator: PropTypes.func.isRequired,
  editOperator: PropTypes.func.isRequired,
  deleteOperator: PropTypes.func.isRequired,
  toggleOperator: PropTypes.func.isRequired,
};

export default OperatorsTable;
