import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Paginator from './Paginator';
import PerPage from './PerPage';

const  Pagination = ({ entriesQty, currentPage, perPage, setPage, setPerPage }) => {
  return <Row className="py-3 justify-content-between">
    <Col xs="2">
      <PerPage current={ perPage } onChange={ setPerPage }/>
    </Col>
    <Col xs="auto">
      <Paginator pages={ Math.ceil(entriesQty / perPage) } current={ currentPage } onChange={ setPage }/>
    </Col>
  </Row>
}

export default Pagination
