import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSync, faFileArrowDown, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { withLoader, withModals, withNotifications } from '@roamingaudit/react-ui-utils';

import axios from '../../../services/axios';
import NumberRangeOptimizerModeEnum from '../NumberRangeOptimizerModeEnum';
import NumberRangeOptimizerDataSourceEnum from '../NumberRangeOptimizerDataSourceEnum';
import NumberRangeOptimizerRoamingPartnersEnum from '../NumberRangeOptimizerRoamingPartnersEnum';
import NumberRangeOptimizerTable from './NumberRangeOptimizerTable/NumberRangeOptimizerTable';
import Pagination from '../../../components/Pagination/Pagination';
import NumberRangeOptimizerAuditModal from '../NumberRangeOptimizerAuditModal/NumberRangeOptimizerAuditModal';

import classes from './NumberRangeOptimizerView.module.css';

class NumberRangeOptimizerView extends Component {
  currentSettings = {
    mode: NumberRangeOptimizerModeEnum.ORIGINAL,
    dataSource: NumberRangeOptimizerDataSourceEnum.NNGT,
    roamingPartners: NumberRangeOptimizerRoamingPartnersEnum.ALL,
    overlapping: false,
  };

  state = {
    ...this.currentSettings,
    rows: [],
    sorting: {
      column: 'country',
      reversed: false
    },
    columns: [],
    total: 0,
    subtotal: 0,
    filters: {},
    page: 1,
    perPage: 50,
    isModified: false
  };

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.props.showLoader();
    this.setState({ rows: [], columns: [] });

    try {
      const { data } = await axios.put(
        '/number-range-optimizer',
        {
          filters: this.state.filters,
          page: this.state.page,
          perPage: this.state.perPage,
          mode: this.state.mode,
          sorting: this.state.sorting,
          dataSource: this.state.dataSource,
          roamingPartners: this.state.roamingPartners,
          overlapping: this.state.overlapping
        }
      );

      this.currentSettings = {
        mode: this.state.mode,
        dataSource: this.state.dataSource,
        roamingPartners: this.state.roamingPartners,
        overlapping: this.state.overlapping
      };

      this.setState({ ...data, isModified: false });
    } catch (e) {
      this.props.processError(e);

      this.setState({ isModified: false });
    }

    this.props.hideLoader();
  }

  clearFilters() {
    this.setState({ filters: {}, page: 1 }, this.fetchData.bind(this))
  }

  async export() {
    this.props.showLoader();

    try {
      const { data } = await axios.put(
        '/number-range-optimizer/export',
        {
          filters: this.state.filters,
          mode: this.state.mode,
          sorting: this.state.sorting,
          dataSource: this.state.dataSource,
          roamingPartners: this.state.roamingPartners,
          overlapping: this.state.overlapping
        }
      );

      window.open('/number-range-optimizer/export/' + data.filename, '_blank');
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async prepareAudit() {
    this.props.showLoader();

    try {
      const { data } = await axios.get('/number-range-optimizer/countries');
      this.props.showModal(NumberRangeOptimizerAuditModal, {
        countries: data.countries,
        onConfirm: this.audit.bind(this)
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async audit(country, userInput) {
    this.props.showLoader();

    try {
      const { data } = await axios.put(
        '/number-range-optimizer/audit',
        {
          mode: this.state.mode,
          dataSource: this.state.dataSource,
          roamingPartners: this.state.roamingPartners,
          country,
          userInput
        }
      );

      window.open('/number-range-optimizer/audit/export/' + data.filename, '_blank');

      this.props.hideModal();
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  selectRoamingPartners(roamingPartners) {
    const newState = { roamingPartners };

    this.setState({ ...newState, isModified: this.processChanges(newState) });
  }

  selectMode(mode) {
    const overlapping = [ NumberRangeOptimizerModeEnum.FULL, NumberRangeOptimizerModeEnum.OPTIMIZED ].indexOf(mode) !== -1
      ? this.state.overlapping
      : false
    const newState = { mode, overlapping };


    this.setState({ ...newState, isModified: this.processChanges(newState) });
  }

  changePage(page) {
    this.setState({ page }, this.fetchData.bind(this));
  }

  setPerPage(perPage) {
    const maxPage = Math.ceil(this.state.total / perPage)
    const page = this.state.page > maxPage ? maxPage : this.state.page
    this.setState({ perPage, page }, this.fetchData.bind(this));
  }

  processChanges(modifiedState) {
    return !(modifiedState.mode === this.currentSettings.mode
      && modifiedState.roamingPartners === this.currentSettings.roamingPartners
      && modifiedState.overlapping === this.currentSettings.overlapping
      && modifiedState.dataSource === this.currentSettings.dataSource)
  }

  targetDatasetChangeHandler(dataSource) {
    const newState = { dataSource }

    this.setState({ ...newState, isModified: this.processChanges(newState) });
  }

  serverFilteringHandler(filters) {
    this.setState({ filters, page: 1 }, this.fetchData.bind(this));
  }

  serverSortingHandler(sorting) {
    this.setState({ sorting }, this.fetchData.bind(this));
  }

  toggleOverlapping() {
    const newState = { overlapping: !this.state.overlapping }

    this.setState({ ...newState, isModified: this.processChanges(newState) })
  }

  areButtonsActive() {
    return [NumberRangeOptimizerModeEnum.ORIGINAL, NumberRangeOptimizerModeEnum.NDC].includes(this.state.mode)
  }

  render() {
    return <>
      <Row>
        <Col xs="auto">
          <Form.Label>Data Source:</Form.Label>
          <Form.Control
            as="select"
            name="dataSource"
            value={ this.state.dataSource }
            onChange={ e => this.targetDatasetChangeHandler(e.target.value) }>
            <option value="NNGT">NNGT</option>
            <option value="MSISDN">MSISDN</option>
          </Form.Control>
        </Col>
        <Col xs="auto">
          <Form.Label>Networks:</Form.Label>
          <Form.Check
            onChange={ this.selectRoamingPartners.bind(this, NumberRangeOptimizerRoamingPartnersEnum.ALL) }
            defaultChecked={ this.state.roamingPartners === NumberRangeOptimizerRoamingPartnersEnum.ALL }
            type="radio"
            id="number-range-optimizer__roaming-partners--all"
            name="number-range-optimizer__roaming-partners"
            label="All Roaming Partners"
          />
          <Form.Check
            onChange={ this.selectRoamingPartners.bind(this, NumberRangeOptimizerRoamingPartnersEnum.INBOUND) }
            defaultChecked={ this.state.roamingPartners === NumberRangeOptimizerRoamingPartnersEnum.INBOUND }
            type="radio"
            id="number-range-optimizer__roaming-partners--inbound"
            name="number-range-optimizer__roaming-partners"
            label="Bilateral & Inbound Roaming Partners"
          />
          <Form.Check
            onChange={ this.selectRoamingPartners.bind(this, NumberRangeOptimizerRoamingPartnersEnum.OUTBOUND) }
            defaultChecked={ this.state.roamingPartners === NumberRangeOptimizerRoamingPartnersEnum.OUTBOUND }
            type="radio"
            id="number-range-optimizer__roaming-partners--outbound"
            name="number-range-optimizer__roaming-partners"
            label="Bilateral & Outbound Roaming Partners"
          />
        </Col>
        <Col xs="auto">
          <Form.Label>Output:</Form.Label>
          <Form.Check
            onChange={ this.selectMode.bind(this, NumberRangeOptimizerModeEnum.ORIGINAL) }
            defaultChecked={ this.state.mode === NumberRangeOptimizerModeEnum.ORIGINAL }
            type="radio"
            id="number-range-optimizer__mode--original"
            name="number-range-optimizer__mode"
            label="Original Number Ranges"
          />
          <Form.Check
            onChange={ this.selectMode.bind(this, NumberRangeOptimizerModeEnum.NDC) }
            defaultChecked={ this.state.mode === NumberRangeOptimizerModeEnum.NDC }
            type="radio"
            id="number-range-optimizer__mode--ndc"
            name="number-range-optimizer__mode"
            label="Optimized NDC"
          />
          <Form.Check
            onChange={ this.selectMode.bind(this, NumberRangeOptimizerModeEnum.FULL) }
            defaultChecked={ this.state.mode === NumberRangeOptimizerModeEnum.FULL }
            type="radio"
            id="number-range-optimizer__mode--full"
            name="number-range-optimizer__mode"
            label="Full Range"
          />
          <Form.Check
            onChange={ this.selectMode.bind(this, NumberRangeOptimizerModeEnum.OPTIMIZED) }
            defaultChecked={ this.state.mode === NumberRangeOptimizerModeEnum.OPTIMIZED }
            type="radio"
            id="number-range-optimizer__mode--optimized"
            name="number-range-optimizer__mode"
            label="Optimized (Shortest Ranges)"
          />
        </Col>
        <Col xs
             className={ `${ classes.panelCell } align-items-end` }>
          Entries displayed: { this.state.subtotal } of { this.state.total }
        </Col>
        <Col xs="auto"
             className={ `${ classes.panelCell } ${ classes.panelStack } align-items-end` }>
          <div>
            <Form.Check
              checked={ this.state.overlapping }
              onChange={ this.toggleOverlapping.bind(this) }
              disabled={ this.areButtonsActive() }
              type="checkbox"
              label="overlapping only"
            />
          </div>
          <div>
            <Button variant="secondary"
                    disabled={ Object.keys(this.state.filters).length === 0 }
                    onClick={ this.clearFilters.bind(this) }
                    title="Clear Filters"
                    className={ classes.panelButton }>
              <FontAwesomeIcon icon={ faXmark }/>
            </Button>
            <Button variant="primary"
                    disabled={ !this.state.isModified }
                    onClick={ this.fetchData.bind(this) }
                    title="Apply New Settings"
                    className={ classes.panelButton }>
              <FontAwesomeIcon icon={ faSync }/>
            </Button>
            <Button variant="success"
                    onClick={ this.export.bind(this) }
                    title="Export to CSV"
                    className={ classes.panelButton }>
              <FontAwesomeIcon icon={ faFileArrowDown }/>
            </Button>
            <Button variant="success"
                    onClick={ this.prepareAudit.bind(this) }
                    disabled={ this.areButtonsActive() }
                    title="Audit and Export"
                    className={ classes.panelButton }>
              <FontAwesomeIcon icon={ faListCheck }/>
            </Button>
          </div>
        </Col>
      </Row>
      <Pagination entriesQty={ this.state.subtotal }
                  currentPage={ this.state.page }
                  perPage={ this.state.perPage }
                  setPage={ this.changePage.bind(this) }
                  setPerPage={ this.setPerPage.bind(this) }/>
      <NumberRangeOptimizerTable
        initialSorting={ this.state.sorting }
        initialFiltering={ this.state.filters }
        serverSortingHandler={ this.serverSortingHandler.bind(this) }
        serverFilteringHandler={ this.serverFilteringHandler.bind(this) }
        rows={ this.state.rows }
        columns={ this.state.columns }/>
      <Pagination entriesQty={ this.state.subtotal }
                  currentPage={ this.state.page }
                  perPage={ this.state.perPage }
                  setPage={ this.changePage.bind(this) }
                  setPerPage={ this.setPerPage.bind(this) }/>
    </>;
  }
}

export default withLoader(withNotifications(withModals(withRouter(NumberRangeOptimizerView))));
