import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { LiteTable } from '@roamingaudit/react-lite-table';

const TemplateTable = props => {
  const actions = row => {
    const editButton = <Button
      variant="primary"
      size="sm"
      title="Edit"
      onClick={ (e) => {
        e.stopPropagation();
        props.editRow(row, true);
      } }>
      <FontAwesomeIcon icon={ faPen }/>
    </Button>;

    const removeButton = <Button
      variant="danger"
      size="sm"
      title="Remove"
      onClick={ (e) => {
        e.stopPropagation();
        props.removeRow(row);
      } }>
      <FontAwesomeIcon icon={ faTimes }/>
    </Button>;

    return [ editButton, removeButton ];
  }

  return <LiteTable
    actions={ actions }
    withFiltering
    columns={ props.columns }
    onFilter={ props.onFilter }
    rows={ props.rows }
    customTableStyle={ { border: '1px solid #ddd' } }
    cellValueResolver={ (row, column) => {
      if (row[column] === undefined && row.anres) {
        return row.anres[column];
      }

      return row[column];
    } }/>;
};

TemplateTable.propTypes = {
  editRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
};

export default TemplateTable;
