import React from 'react'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { ButtonSpinner, Modal } from '@roamingaudit/react-ui-utils'
import { useFormik } from 'formik'
import { Col, Form } from 'react-bootstrap'

import Multiselect from '../../../../components/Multiselect/Multiselect'

const SdmRoamschmModal = ({availableSchemes, availableFlags, availableAgreements, onConfirm, hideModal}) => {
  const formik = useFormik({
    initialValues: {
      scheme: availableSchemes[0],
      agreements: [],
      flags: []
    },
    onSubmit: values => onConfirm('PARTNER_FLAG', null, values)
  })

  return <Modal
    actions={
      <>
        <Button variant="secondary" onClick={ hideModal }>Close</Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting }
                form="sdm-roamschmvlr-audit-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Proceed
        </Button>
      </>
    }
    hideModal={ hideModal }
    title="SDM Roamschmvlr Details">
    <Form
      noValidate
      id="sdm-roamschmvlr-audit-form"
      onSubmit={ formik.handleSubmit }
    >
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="12"
          controlId='form-scheme'>
          <Form.Label>Select Scheme</Form.Label>
          <Form.Control
            as="select"
            name="scheme"
            value={ formik.values.scheme }
            onChange={ formik.handleChange }>
            { availableSchemes.map(scheme => <option value={ scheme }>{ scheme }</option>) }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            { formik.errors.scheme }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="12"
          controlId='form-flag'>
          <Form.Label>Select Agreements</Form.Label>
          <Multiselect options={ availableAgreements }
                       selected={formik.values.agreements}
                       onChange={ val => formik.setFieldValue('agreements', val) }/>
          <Form.Control.Feedback type="invalid">
            { formik.errors.agreements }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      { (availableFlags.length > 0 && <Form.Row>
        <Form.Group
          as={ Col }
          xs="12"
          controlId='form-flag'>
          <Form.Label>Select Partner Flags</Form.Label>
          <Multiselect options={ availableFlags }
                       selected={formik.values.flags}
                       onChange={ val => formik.setFieldValue('flags', val) }/>
          <Form.Control.Feedback type="invalid">
            { formik.errors.flags }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>) }
    </Form>
  </Modal>
}

SdmRoamschmModal.propTypes = {
  availableAgreements: PropTypes.arrayOf(PropTypes.any),
  availableSchemes: PropTypes.arrayOf(PropTypes.any),
  availableFlags: PropTypes.arrayOf(PropTypes.any),
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SdmRoamschmModal
