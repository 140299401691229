import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@roamingaudit/react-ui-utils';

import SccpCheckTable from './SccpCheckTable/SccpCheckTable';

const SccpCheckModal = props => {
  return <Modal
    size="lg"
    title="IR.21 SCCP Information"
    hideModal={ props.hideModal }>
    <SccpCheckTable rows={ props.data }/>
  </Modal>;
};

SccpCheckModal.propTypes = {
  data: PropTypes.object,
  hideModal: PropTypes.func
};

export default SccpCheckModal;
