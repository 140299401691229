import React from 'react';
import { LiteTable } from '@roamingaudit/react-lite-table';

const NumberRangeOptimizerTable = props => <LiteTable
  rows={ props.rows }
  serverSortingHandler={ props.serverSortingHandler }
  customFilter={ props.customFilter }
  onFilter={ props.onFilter }
  serverFilteringHandler={ props.serverFilteringHandler }
  initialFiltering={ props.initialFiltering }
  initialSorting={ props.initialSorting }
  columnsMap={ {
      'organization': 'Organization',
      'brand': 'Brand',
      'country': 'Country',
      'tadig': 'TADIG',
      'hub': 'Hub',
      'mcc': 'MCC',
      'mnc': 'MNC',
      'cc': 'CC',
      'ndc': 'NDC',
      'rangeStart': 'Start',
      'rangeStop': 'Stop',
      'optimizedNdc': 'New NDC',
      'optimizedRangeStart': 'New Start',
      'optimizedRangeStop': 'New Stop',
      'gt': 'Optimized',
  } }
  withSorting
  withFiltering/>

export default NumberRangeOptimizerTable;
