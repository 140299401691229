import React, { Component } from 'react';
import Cookies from 'js-cookie'
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import connect from 'react-redux/lib/connect/connect';
import { faBars, faChevronDown, faChevronUp, faLock, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BlockedFeatureModal from '../BlockedFeatureModal/BlockedFeatureModal';
import menuConfig from './MenuConfig';

import classes from './Menu.module.css';

class MenuStateEnum {
  static WIDE = 'expanded';
  static NARROW = 'collapsed';
}

class Menu extends Component {
  menuStateKey = 'radim-type';
  expansionsStateKey = 'managements';
  expandables = [];

  state = {
    menuFlavor: Cookies.get(this.menuStateKey) || MenuStateEnum.WIDE,
    blockedFeatureName: null,
    blockedFeatureGroup: null,
    expansions: JSON.parse(window.localStorage.getItem(this.expansionsStateKey)) || {}
  };

  toggleMenu() {
    const menuFlavor = this.state.menuFlavor === MenuStateEnum.WIDE ? MenuStateEnum.NARROW : MenuStateEnum.WIDE;

    Cookies.set(this.menuStateKey, menuFlavor, { expires: 365, path: '/' });

    this.setState({ menuFlavor });
  }

  toggleSubmenu(feature) {
    const expansions = { ...this.state.expansions };

    if (this.state.expansions[feature]) {
      Object.keys(expansions).forEach(item => item.indexOf(feature) === 0 ? delete expansions[item] : null);

      window.localStorage.setItem(this.expansionsStateKey, JSON.stringify(expansions));
      this.setState({ expansions });
      return;
    }

    expansions[feature] = feature;
    window.localStorage.setItem(this.expansionsStateKey, JSON.stringify(expansions));
    this.setState({ expansions });
  }

  getMenuItem(item, isSubItem) {
    if (!this.props.user || item.roles.indexOf(this.props.user.role) === -1) {
      return null;
    }

    const menuItemClassesList = [ classes.menuBaseItem, isSubItem ? classes.menuSubItem : classes.menuItem ];
    if (this.isCurrent(item)) {
      menuItemClassesList.push(classes['menuItem--current']);
    }

    const itemElement = this.buildItem(item, isSubItem);

    return <li className={ menuItemClassesList.join(' ') }>
      {
        item.submenu && item.submenu.length > 0
          ? this.buildItemWithSubmenu(itemElement, item, isSubItem)
          : itemElement
      }
    </li>
  }

  isCurrent(item) {
    if (!item.href) {
      return false;
    }

    let matcher = item.href && item.href.pathname ? item.href.pathname : item.href;

    return item.strictMatch ? this.props.location.pathname === matcher : this.props.location.pathname.indexOf(matcher) === 0;
  }

  showFeatureModal(featureName, featureGroup) {
    this.setState({ featureName, featureGroup })
  }

  hideFeatureModal() {
    this.setState({ featureName: null, featureGroup: null });
  }

  buildItem(item, isSubItem) {
    const hasSubMenus = item.submenu && item.submenu.length > 0;

    const menuItemLinkClassesList = [ classes.menuItemLink ];
    if (this.isCurrent(item)) {
      menuItemLinkClassesList.push(classes['menuItemLink--current']);
    }

    if (isSubItem) {
      menuItemLinkClassesList.push(classes.menuSubItemLink);
    }

    const isBlocked = this.props.user.blockedMenus.indexOf(item.label) !== -1 && item.feature;
    let onClickHandler;
    if (hasSubMenus) {
      onClickHandler = this.toggleSubmenu.bind(this, item.rootOf);
    } else if (isBlocked) {
      onClickHandler = this.showFeatureModal.bind(this, item.label, item.feature.split('-')[0]);
    }

    const itemElementBody = <>
      {
        typeof item.icon === 'string'
          ? <img className={ classes.menuItemIcon } src={ '/images/' + item.icon + '.png' }
                 alt={ item.label }/>
          : <FontAwesomeIcon className={ classes.menuItemFontIcon } icon={ item.icon }/>
      }
      <span className={ classes.menuItemLabel }>
          <div className={ classes.menuItemLabelWrapper }>
            <div className={ classes.menuItemLabelText }>{ item.label }</div>
            {
              hasSubMenus
                ? (
                  this.state.expansions[item.rootOf]
                  ? <FontAwesomeIcon className={ classes.menuRightIcon } icon={ faChevronUp }/>
                  : <FontAwesomeIcon className={ classes.menuRightIcon } icon={ faChevronDown }/>
                )
                : (isBlocked ? <FontAwesomeIcon className={ classes.menuRightIcon } icon={ faLock }/> : null)
            }
          </div>
        </span>
    </>;

    return item.href && item.href.pathname
      ? <Link className={ menuItemLinkClassesList.join(' ') }
              to={ item.href }>
        { itemElementBody }
      </Link>
      : <a className={ menuItemLinkClassesList.join(' ') }
           href={ hasSubMenus || isBlocked ? 'javascript://' : item.href }
           onClick={ onClickHandler }>
        { itemElementBody }
      </a>;
  }

  wrapItemWithSubmenu(item, isSubMenu) {
    return isSubMenu
      ? item
      : <div className={ classes.menuItemRelativeBox }>{ item }</div>
  }

  buildItemWithSubmenu(itemElement, item, isSubMenu) {
    this.expandables.push(item.rootOf);

    return this.wrapItemWithSubmenu(
      <>
        { itemElement }
        <Collapse
          in={ this.isCurrent(item) || this.state.expansions[item.rootOf] }>
          <ul className={ classes.menuItems + ' ' + classes.menuSubItems }>
            {
              item.submenu.map(subItem => this.getMenuItem(subItem, true))
            }
          </ul>
        </Collapse>
      </>,
      isSubMenu
    );
  }

  collapseAll() {
    window.localStorage.setItem(this.expansionsStateKey, '{}');
    this.setState({ expansions: {} });
  }

  expandAll() {
    const expansions = {};
    this.expandables.forEach(item => expansions[item] = item);

    window.localStorage.setItem(this.expansionsStateKey, JSON.stringify(expansions));
    this.setState({ expansions });
  }

  render() {
    const areThereExpanded = Object.keys(this.state.expansions).length > 0;
    return <>
      <ul
        className={ [ classes.menuItems, classes.menuItemsRoot,  (this.state.menuFlavor === MenuStateEnum.WIDE ? '' : classes['menuItems--narrow'])].join(' ') }>
        <li className={ classes.menuBaseItem + ' ' + classes.menuToggleItem }>
          <Button onClick={ this.toggleMenu.bind(this) }
                  variant="outline-secondary"
                  className={ classes.menuToggleButton }>
            <FontAwesomeIcon className={ classes.menuToggleIcon } icon={ faBars }/>
          </Button>
          <FontAwesomeIcon
            onClick={ areThereExpanded ? this.collapseAll.bind(this) : this.expandAll.bind(this) }
            className={ classes.expander }
            icon={ areThereExpanded ? faMinus : faPlus }/>
        </li>
        {
          menuConfig.map((item, i) => this.getMenuItem(item, false))
        }
      </ul>
      {
        this.state.blockedFeatureName && this.state.blockedFeatureGroup
          ? <BlockedFeatureModal
            featureGroup={ this.state.blockedFeatureGroup }
            featureBlockedUrl={ this.props.user.menuUrls[this.state.blockedFeatureGroup] }
            featureName={ this.state.blockedFeatureName }
            hideModal={ this.hideFeatureModal.bind(this) }/>
          : null
      }
    </>;
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default withRouter(connect(mapStateToProps)(Menu));
