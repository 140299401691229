import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { Modal } from '@roamingaudit/react-ui-utils';

import { ExtraInfo } from './ExtraInfo';

const buildInitialFilter = (fields, payload) => fields.reduce((obj, field) => {
  obj[field.name] = payload?.[field.name] ?? '';
  return obj;
}, {});

const ExtraInfoModal = ({ extraInfo, payload, hideModal, onConfirm }) => {
  const [filters, setFilters] = useState(buildInitialFilter(extraInfo.fields, payload));

  const handleFilterChange = (key, value) => {
    setFilters({...filters, [key]: value});
  }

  return <Modal
    actions={
      <>
        <Button variant="secondary" onClick={ hideModal }>Close</Button>
        <Button variant="primary"
                id="extraInfoRunAuditButton"
                onClick={ () => onConfirm(filters) }>
          Run Audit
        </Button>
      </>
    }
    hideModal={ hideModal }
    title={ extraInfo.title }>
    <div style={{display: 'flex', flexDirection: 'column'}}>
    {
      extraInfo.fields.map(field => {
        return <label style={{display: 'flex', flexDirection: 'column'}}>
          <span style={{fontWeight: '700'}}>
            { field.name }
          </span>
          <input type="text"
                 key={ field.name }
                 value={ filters[field.name] }
                 onInput={(e) => handleFilterChange(field.name, e.target.value)}
                 placeholder={ field.placeholder }/>
        </label>
      })
    }
    </div>
  </Modal>
}

ExtraInfoModal.propTypes = {
  extraInfo: PropTypes.objectOf(ExtraInfo),
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
};

export default ExtraInfoModal;
