import React  from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, Form } from 'react-bootstrap';
import { ButtonSpinner, Modal } from '@roamingaudit/react-ui-utils';

const ApplyFlagsModal = ({ tadigsMap, onConfirm, hideModal }) => {
  const flagIds = Object.keys(tadigsMap);
  const schema = yup.object({
    flag: yup.string()
      .oneOf(flagIds)
      .required('This field is a obligatory.'),
    tadigs: yup.string().matches(
      /^[0-9a-z]{5}(?:\n[0-9a-z]{5})*$/ig,
      'Should contain only new line separated TADIGs')
      .required('This field is a obligatory.'),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      flag: flagIds[0],
      tadigs: tadigsMap[flagIds[0]].tadigs.join('\n'),
    },
    onSubmit: preferences => {
      onConfirm(preferences.flag, preferences.tadigs)
      hideModal()
    }
  });

  const selectFlag = newFlag => {
    formik.setFieldValue('flag', newFlag)
    formik.validateField('flag')
    formik.setFieldValue('tadigs', tadigsMap[newFlag].tadigs.join('\n'))
  }

  return <Modal
    actions={
      <>
        <Button variant="secondary" onClick={ hideModal }>Close</Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting || !formik.dirty || !formik.isValid }
                form="partner-flags-apply-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Apply
        </Button>
      </>
    }
    hideModal={ hideModal }
    title="Apply Partner Flags to TADIGs">
    <Form
      noValidate
      id="partner-flags-apply-form"
      onSubmit={ formik.handleSubmit }>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="12"
          controlId='form-flag'>
          <Form.Label>Partner Flag</Form.Label>
          <Form.Control
            as="select"
            name="flag"
            value={ formik.values.flag }
            onBlur={ formik.handleBlur }
            isValid={ !formik.errors.flag }
            isInvalid={ !!formik.errors.flag }
            onChange={ e => selectFlag(e.target.value) }>
            { Object.keys(tadigsMap).map(id => <option value={ id }>{ tadigsMap[id].name }</option>) }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            { formik.errors.flag }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="12"
          controlId='form-tadigs'>
          <Form.Label>Insert TADIGs to map</Form.Label>
          <Form.Control
            as="textarea"
            name="tadigs"
            rows={ 5 }
            value={ formik.values.tadigs }
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            isValid={ formik.touched.tadigs && !formik.errors.tadigs }
            isInvalid={ !!formik.errors.tadigs && !formik.touched.tadigs }
            placeholder=''/>
          <Form.Control.Feedback type="invalid">
            { formik.errors.tadigs }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    </Form>
  </Modal>
}

ApplyFlagsModal.propTypes = {
  tadigsMap: PropTypes.object,
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
};

export default ApplyFlagsModal;
