import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Modal } from '@roamingaudit/react-ui-utils';

import classes from './BulkUploadBriefModal.module.css';

const BulkUploadBriefModal = props => <Modal
  hideModal={ props.hideModal }
  actions={
    <>
      <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
      {
        props.data.found > 0
          ? <Button variant="primary" type="button" onClick={ () => props.submitActionHandler(props.data) }>
            Continue
          </Button>
          : null
      }
      {
        props.data.found > 0 && props.data.unknown > 0
          ? <Button variant="primary" type="button" onClick={ () => props.submitActionHandler(props.data, true) }>
            Create Unknown & Continue
          </Button>
          : null
      }
    </>
  } title="Bulk Upload Summary">
  <Container>
    <Row>
      <Col xs="1" className={ classes.statValue }>{ props.data.defined }</Col>
      <Col>nodes Defined</Col>
    </Row>
    <Row>
      <Col xs="1" className={ classes.statValue }>{ props.data.found }</Col>
      <Col>print-outs found in the file</Col>
    </Row>
    <Row>
      <Col xs="1" className={ classes.statValue }>{ props.data.match }</Col>
      <Col>match a network element name</Col>
    </Row>
    <Row>
      <Col xs="1" className={ classes.statValue }>{ props.data.unknown }</Col>
      <Col>unknown</Col>
    </Row>
    <Row>
      <Col xs="1" className={ classes.statValue }>{ props.data.missing }</Col>
      <Col>network elements are missing print-outs</Col>
    </Row>
    <Row>
      <Col xs="1" className={ classes.statValue }>{ props.data.duplicates }</Col>
      <Col>duplicate print-outs found in the file</Col>
    </Row>
  </Container>
</Modal>;

BulkUploadBriefModal.propTypes = {
  data: PropTypes.object.isRequired,
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default BulkUploadBriefModal;
