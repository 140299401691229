import React from 'react';
import { LiteTable } from '@roamingaudit/react-lite-table';

const ConfigurationsTable = props => <LiteTable
  columns={ props.columns }
  rows={ props.rows }
  withCheckboxes
  checkboxesHandler={ props.checkboxesHandler }
  columnsMap={ {
    'name': 'NE Name',
    'entries': 'Entries',
    'lastUpdated': 'Last Update',
    'lastAudited': 'Last Audit'
  } }/>;

export default ConfigurationsTable;
