import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { LiteTable } from '@roamingaudit/react-lite-table';

const GenerateTemplateTable = props => <LiteTable
  columns={ props.columns }
  rows={ props.rows }
  actions={ row => <Button
    variant="primary"
    size="sm"
    title="Check"
    onClick={ () => props.onSelectNetworkElement(row) }>
    <FontAwesomeIcon icon={ faCheck }/>
  </Button> }/>

GenerateTemplateTable.propTypes = {
  onSelectNetworkElement: PropTypes.func.isRequired,
};

export default GenerateTemplateTable;
