import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Modal } from '@roamingaudit/react-ui-utils';

import GenerateTemplateTable from '../GenerateTemplateTable/GenerateTemplateTable';

const GenerateTemplateModal = props => <Modal
  actions={
    <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
  }
  hideModal={ props.hideModal }
  title="Generate Template">
  <GenerateTemplateTable
    columns={ props.columns }
    rows={ props.rows }
    onSelectNetworkElement={ props.onSelectNetworkElement }/>
</Modal>;

GenerateTemplateModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onSelectNetworkElement: PropTypes.func.isRequired
};

export default GenerateTemplateModal;
