import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { LiteTable } from '@roamingaudit/react-lite-table';

const IpRangesTable = props => <LiteTable
  rows={ props.rows }
  columns={ props.columns }
  columnsMap={ { 'name': 'Name', 'ip_address_range': 'IP Address Range', 'ips_qty': 'IPs Qty', 'country': 'Country' } }
  customTableStyle={ { border: '1px solid #ddd' } }
  withFiltering
  actions={ row => row.ip_address_range
    ? <Button
      variant="primary"
      size="sm"
      title="Lookup IP Geolocation"
      onClick={ (e) => {
        e.stopPropagation();
        props.lookup(row);
      } }>
      <FontAwesomeIcon icon={ faSearchLocation }/>
    </Button>
    : null }/>;

export default IpRangesTable;
