import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { ConfirmationModal, withLoader, withModals, withNotifications } from '@roamingaudit/react-ui-utils';

import axios from '../../../services/axios';
import TabSelector from '../../../components/TabSelector/TabSelector';
import Header from '../../../components/TabSelector/Header/Header';
import TemplateComparisonTable from './TemplateComparisonTable/TemplateComparisonTable';
import ModifyEntryModal from '../ModifyEntryModal/ModifyEntryModal';
import AddRoamingProfileModal from './AddRoamingProfileModal/AddRoamingProfileModal';
import RowAnalysis from '../../../components/RowAnalysis/RowAnalysis';

import classes from '../TemplateComparisonView/TemplateComparisonView.module.css';

class TemplateComparisonView extends Component {
  state = {
    comparison: null,
    anres: [],
    chars: [],
    tabs: [],
    currentTab: null,
    checkWithAnalysis: false,
    showCheckForRow: null
  };

  vendorId;
  typeId;
  subtypeId;

  componentDidMount() {
    this.vendorId = this.props.match.params.vendor;
    this.typeId = this.props.match.params.type;
    this.subtypeId = this.props.match.params.subtype;

    this.loadComparison();
  }

  async modifyTemplateEntry(data, isEditing) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      if (data.anres) {
        data.anres = JSON.stringify(data.anres);
      }

      const method = isEditing ? 'put' : 'post';
      await axios[method](`/templates/${ this.vendorId }/${ this.typeId }/${ this.subtypeId }/entry`, { entry: data });

      const comparisonData = await this.fetchComparison();

      this.setState({ ...comparisonData, });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  selectTab(tab) {
    this.setState({ currentTab: tab });
  }

  async loadComparison() {
    this.props.showLoader();
    this.setState({ data: null });

    try {
      const comparisonData = await this.fetchComparison();
      const tabs = comparisonData.comparison ? Object.keys(comparisonData.comparison).map(tab => tab.toUpperCase()) : [];

      this.setState({

        ...comparisonData,
        tabs,
        currentTab: tabs.length > 0 ? tabs[0] : null
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async fetchComparison() {
    const response = await axios.get([ '/templates/compare', this.vendorId, this.typeId, this.subtypeId, 'json' ].join('/'));

    return response.data
  }

  async startEntryModifications(row = null, isEditing = false) {
    this.props.showLoader();

    try {
      const entryFields = await this.fetchEntryFields();
      const modifyingRow = this.fillEditableRow(entryFields, row);

      this.props.showModal(ModifyEntryModal, {
        anresFields: this.state.anres,
        entryFields,
        existingEntry: modifyingRow,
        isEditing,
        chars: this.state.chars,
        submitActionHandler: this.modifyTemplateEntry.bind(this)
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async fetchEntryFields() {
    const response = await axios.get(
      [
        '/templates',
        this.vendorId,
        this.typeId,
        this.subtypeId,
        'entry-fields'
      ].join('/')
    );

    return response.data.filter(field => field !== 'area');
  }

  fillEditableRow(entryFields, row) {
    if (!row) {
      return null;
    }

    const newRow = JSON.parse(JSON.stringify(row));

    if (!newRow.na && entryFields.indexOf('na') !== -1) {
      newRow.na = 4;
    }

    if (newRow.imsi) {
      if (!newRow.replace && entryFields.indexOf('replace') !== -1) {
        newRow.replace = newRow.imsi.toString().length;
      }

      if (!newRow.msisdn && entryFields.indexOf('msisdn') !== -1) {
        newRow.msisdn = newRow.cc_mgt + newRow.nc_mgt;
      }
    } else {
      if (!newRow.gt && entryFields.indexOf('gt') !== -1) {
        newRow.gt = newRow.cc + newRow.ndc;
      }

      if (!newRow.chars && entryFields.indexOf('chars') !== -1) {
        newRow.chars = null
      }
    }

    Object.keys(newRow).forEach(key => {
      if (entryFields.indexOf(key) === -1) {
        delete newRow[key];
      }
    });

    return newRow;
  }

  async removeException(exceptionId) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.delete([
        'templates/compare/exception',
        exceptionId,
        'element',
        this.vendorId,
        this.typeId,
        this.subtypeId
      ].join('/'));

      const comparisonData = await this.fetchComparison();

      this.setState({ ...comparisonData })
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async removeTemplateEntry(templateId) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.delete([
        '/templates',
        this.vendorId,
        this.typeId,
        this.subtypeId,
        'entry',
        templateId
      ].join('/'));

      const comparisonData = await this.fetchComparison();

      this.setState({ ...comparisonData });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async removeRoamingProfileEntry(ir21Id, roamingPartnerId) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.delete([
        '/templates/compare/roaming-partner',
        roamingPartnerId,
        'ir21',
        ir21Id
      ].join('/'));

      const comparisonData = await this.fetchComparison();

      this.setState({ ...comparisonData });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async addImsiToExceptions(imsi) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.post([
        '/templates/compare/exception',
        this.state.currentTab.toLowerCase(),
        imsi
      ].join('/'));

      const comparisonData = await this.fetchComparison();

      this.setState({ ...comparisonData })
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async addRoamingAreaToExceptions(data) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.post('/templates/compare/exception/roamingarea', {
        exception: {
          source: this.state.currentTab,
          ...data
        }
      });

      const comparisonData = await this.fetchComparison();

      this.setState({ ...comparisonData });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async startEditingTemplateEntry(row) {
    this.props.showLoader();

    try {
      const response = await axios.get(
        [
          '/templates/compare',
          this.vendorId,
          this.typeId,
          this.subtypeId,
          'template',
          row.templateId
        ].join('/')
      );

      const entryFields = await this.fetchEntryFields();
      const modifyingRow = this.fillEditableRow(entryFields, response.data);
      modifyingRow.id = row.templateId;

      this.props.showModal(ModifyEntryModal, {
        anresFields: this.state.anres,
        entryFields,
        existingEntry: modifyingRow,
        isEditing: true,
        chars: this.state.chars,
        submitActionHandler: this.modifyTemplateEntry.bind(this)
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async addToRoamingProfile(data, entryId) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.post('/templates/compare/roaming-partner/ir21/' + entryId, data);

      const comparisonData = await this.fetchComparison();

      this.setState({ ...comparisonData });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  showCheckForRow(row, withAnalysis = false) {
    this.setState({ showCheckForRow: row, checkWithAnalysis: withAnalysis });
  }

  onHideAnalysis() {
    this.setState({ showCheckForRow: null, checkWithAnalysis: false });
  }

  render() {
    if (this.state.tabs.length === 0) {
      return null;
    }

    const currentTabData = this.state.comparison[this.state.currentTab.toLowerCase()];

    return <>
      <TabSelector
        clicked={ this.selectTab.bind(this) }
        current={ this.state.currentTab }
        list={ this.state.tabs }>
        <Header>
          <div>{ currentTabData.stats }</div>
          <Button
            className={ classes.headerButton }
            variant="success"
            title="Add Entry"
            size="sm"
            onClick={ () => this.startEntryModifications() }>
            <FontAwesomeIcon icon={ faPlus }/> Add Entry
          </Button>
        </Header>
      </TabSelector>
      <TemplateComparisonTable
        key={ this.state.currentTab.toLowerCase() }
        addToRoamingProfile={ row => this.props.showModal(AddRoamingProfileModal, {
          tadig: row.TADIG,
          entryId: row.id,
          submitActionHandler: this.addToRoamingProfile.bind(this)
        }) }
        addToTemplates={ this.startEntryModifications.bind(this) }
        addToRoamingAreaTemplate={ this.startEntryModifications.bind(this) }
        editTemplateEntry={ this.startEditingTemplateEntry.bind(this) }
        addRoamingAreaToException={ row => this.props.showModal(ConfirmationModal, {
          question: "Do you want to add this Roaming Area to exceptions?",
          onYes: this.addRoamingAreaToExceptions.bind(this, {
            cc: row.cc,
            ndc: row.ndc,
            start: row.range_start,
            stop: row.range_stop
          })
        }) }
        addImsiToException={ row => this.props.showModal(ConfirmationModal, {
          question: "Do you want to add this IMSI to exceptions?",
          onYes: this.addImsiToExceptions.bind(this, row.imsi)
        }) }
        removeFromRoamingProfile={ row => this.props.showModal(ConfirmationModal, {
          question: "Do you want to remove this entry from roaming profile?",
          onYes: this.removeRoamingProfileEntry.bind(this, row.id, row.roamingPartnerId)
        }) }
        removeFromTemplates={ row => this.props.showModal(ConfirmationModal, {
          question: "Do you want to remove this entry from templates?",
          onYes: this.removeTemplateEntry.bind(this, row.templateId)
        }) }
        removeException={ row => this.props.showModal(ConfirmationModal, {
          question: "Do you want to remove this entry from exceptions?",
          onYes: this.removeException.bind(this, row.exceptionId)
        }) }
        showCheckForRow={ this.showCheckForRow.bind(this) }
        actions={ currentTabData ? currentTabData.actions : [] }
        rows={ currentTabData ? currentTabData.data : [] }
        columns={ currentTabData ? currentTabData.columns : [] }/>
      {
        this.state.showCheckForRow
          ? <RowAnalysis
            onHideCallback={ this.onHideAnalysis.bind(this) }
            url={ `/templates/compare/${ this.vendorId }/${ this.typeId }/${ this.subtypeId }/analyze` }
            row={ this.state.showCheckForRow }
            showFullAnalysis={ this.state.checkWithAnalysis }/>
          : null
      }
    </>;
  }
}

export default withLoader(withNotifications(withModals(withRouter(TemplateComparisonView))));
