import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faFileExcel, faSync } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { withLoader, withModals, withNotifications } from '@roamingaudit/react-ui-utils';

import axios from '../../../services/axios';
import SteeringTable from './SteeringTable/SteeringTable';
import SteeringModal from './SteeringModal/SteeringModal';
import SteeringModeEnum from '../SteeringModeEnum';
import SteeringDataSourceEnum from '../SteeringDataSourceEnum';

import classes from './SteeringView.module.css';

class RoamingPartnersFiltrationEnum {
  static NONE = 'NONE';
  static ALL = 'ALL';
  static OUTBOUND = 'OUTBOUND';
  static INBOUND = 'INBOUND';
}

class SteeringView extends Component {
  currentSettings = {
    mode: SteeringModeEnum.MODIFIED,
    dataSource: SteeringDataSourceEnum.NNGT,
    roamingPartners: RoamingPartnersFiltrationEnum.NONE,
    hubsToExclude: '',
  };

  state = {
    ...this.currentSettings,
    rows: [],
    columns: [],
    filteredQty: 0,
    isModified: false
  };

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.props.showLoader();
    this.setState({ rows: [], columns: [] });

    try {
      const response = await axios.put(
        '/steering',
        {
          mode: this.state.mode,
          hubsToExclude: this.state.hubsToExclude,
          dataSource: this.state.dataSource,
          roamingPartners: this.state.roamingPartners
        }
      );

      this.currentSettings = {
        mode: this.state.mode,
        hubsToExclude: this.state.hubsToExclude,
        roamingPartners: this.state.roamingPartners
      };

      this.setState({ ...response.data, isModified: false });
    } catch (e) {
      this.props.processError(e);

      this.setState({ isModified: false });
    }

    this.props.hideLoader();
  }

  async showGroupedData() {
    this.props.showLoader();

    try {
      const response = await axios.put(
        '/steering/' + this.state.mode + '/grouped',
        {
          hubsToExclude: this.state.hubsToExclude,
          dataSource: this.state.dataSource,
          roamingPartners: this.state.roamingPartners
        }
      );

      this.props.showModal(SteeringModal, {
        mode: this.state.mode,
        columns: response.data.columns,
        rows: response.data.rows
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  filterHubs(hubsToExclude) {
    const newState = { hubsToExclude };

    this.setState({ ...newState, isModified: this.processChanges(newState) });
  }

  changeMode() {
    const newState = { mode: this.state.mode === SteeringModeEnum.OPTIMIZED ? SteeringModeEnum.MODIFIED : SteeringModeEnum.OPTIMIZED };

    this.setState({ ...newState, isModified: this.processChanges(newState) });
  }

  async export() {
    this.props.showLoader();

    try {
      const response = await axios.put(
        '/steering/export',
        {
          mode: this.currentSettings.mode,
          hubsToExclude: this.currentSettings.hubsToExclude,
          roamingPartners: this.currentSettings.roamingPartners
        }
      );

      window.open('/steering/export/' + response.data.filename, '_blank');
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  selectRoamingPartners(roamingPartners) {
    const newState = { roamingPartners };

    this.setState({ ...newState, isModified: this.processChanges(newState) });
  }

  processChanges(modifiedState) {
    return !(modifiedState.mode === this.currentSettings.mode
      && modifiedState.roamingPartners === this.currentSettings.roamingPartners
      && modifiedState.dataSource === this.currentSettings.dataSource
      && modifiedState.hubsToExclude.replace(',', '')
      === this.currentSettings.hubsToExclude.replace(',', ''));
  }

  filterHandler(qty) {
    if (this.state.filteredQty !== qty) {
      this.setState({ filteredQty: qty });
    }
  }

  targetDatasetChangeHandler(dataSource) {
    const newState = { dataSource }

    this.setState({ ...newState , isModified: this.processChanges(newState) });
  }

  render() {
    return <>
      <Row>
        <Col xs="auto">
          <Form.Check
            onChange={ this.selectRoamingPartners.bind(this, RoamingPartnersFiltrationEnum.NONE) }
            defaultChecked={ this.state.roamingPartners === RoamingPartnersFiltrationEnum.NONE }
            type="radio"
            name="steering__roaming-partners"
            label="All NNGT Ranges"
          />
          <Form.Check
            onChange={ this.selectRoamingPartners.bind(this, RoamingPartnersFiltrationEnum.ALL) }
            defaultChecked={ this.state.roamingPartners === RoamingPartnersFiltrationEnum.ALL }
            type="radio"
            name="steering__roaming-partners"
            label="All Roaming Partners NNGT"
          />
          <Form.Check
            onChange={ this.selectRoamingPartners.bind(this, RoamingPartnersFiltrationEnum.OUTBOUND) }
            defaultChecked={ this.state.roamingPartners === RoamingPartnersFiltrationEnum.OUTBOUND }
            type="radio"
            name="steering__roaming-partners"
            label="Bilateral & Outbound Roaming Partners NNGT"
          />
        </Col>
        <Col xs="auto">
          <Form.Label>Excluding Hubs:</Form.Label>
          <Form.Control
            type="text"
            name="hubs"
            pattern="^[0-9a-zA-Z_,]*$"
            placeholder="L2O,KEY2ROAM..."
            value={ this.state.hubsToExclude }
            onChange={ event => this.filterHubs(event.target.value) }/>
        </Col>
        <Col xs>
          <Form.Label>Data Source:</Form.Label>
          <Form.Control
            as="select"
            name="dataSource"
            value={ this.state.dataSource }
            onChange={ e => this.targetDatasetChangeHandler(e.target.value) }>
            <option value={ SteeringDataSourceEnum.NNGT }>NNGT</option>
            <option value={ SteeringDataSourceEnum.MSISDN }>MSISDN</option>
          </Form.Control>
        </Col>
        <Col xs="auto"
             className={ `${ classes.panelCell } ${ classes.panelStack }` }>
          <Form.Check
            type="switch"
            id="steering__mode"
            defaultChecked={ this.state.mode === SteeringModeEnum.OPTIMIZED }
            onChange={ this.changeMode.bind(this) }
            label="Apply optimization"
          />
          <div>Entries displayed: { this.state.filteredQty }</div>
        </Col>
        <Col xs="auto"
             className={ classes.panelCell }>
          <Button variant="primary"
                  disabled={ !this.state.isModified }
                  onClick={ this.fetchData.bind(this) }
                  className={ classes.panelButton }>
            <FontAwesomeIcon icon={ faSync }/>
          </Button>
          <Button variant="primary"
                  onClick={ this.showGroupedData.bind(this) }
                  className={ classes.panelButton }>
            <FontAwesomeIcon icon={ faClipboardCheck }/>
          </Button>
          <Button variant="success"
                  onClick={ this.export.bind(this) }
                  className={ classes.panelButton }>
            <FontAwesomeIcon icon={ faFileExcel }/>
          </Button>
        </Col>
      </Row>
      <SteeringTable
        rows={ this.state.rows }
        columns={ this.state.columns }
        onFilter={ this.filterHandler.bind(this) }/>
    </>;
  }
}

export default withLoader(withNotifications(withModals(withRouter(SteeringView))));
