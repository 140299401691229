import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ReactReduxContext } from 'react-redux';

import classes from './Header.module.css';
import ProfileWidget from '../../ProfileWidget/ProfileWidget';
import TastesEnum from '../TastesEnum';
import PropTypes from "prop-types";

const Header = props => <Row className={ classes.header }>
  <Col xs={ { span: 2, offset: props.taste === TastesEnum.CLIENT ? 0 : 2 } }>
    <a href="/"><img src="/react/images/logo.svg" className={ classes.logo } alt="Roamingaudit Logo"/></a>
  </Col>
  <Col xs={ { span: 4, offset: props.taste === TastesEnum.CLIENT ? 6 : 2 } } className={classes.widget}>
    {
      props.taste === TastesEnum.CLIENT
      ? <ProfileWidget context={ ReactReduxContext }/>
        : <Row>
          <Col xs={ 4 }>
            <a href="https://web.facebook.com/roamingaudit" title="Roamingaudit at Facebook" rel="noopener noreferrer" target="_blank">
              <img src="/react/images/auth/icon-f.png" alt="Roamingaudit at Facebook" />
            </a>
          </Col>
          <Col xs={ 4 }>
            <a href="https://twitter.com/roamingaudit" title="Roamingaudit at Twitter" rel="noopener noreferrer" target="_blank">
              <img src="/react/images/auth/icon-t.png" alt="Roamingaudit at Twitter"/>
            </a>
          </Col>
          <Col xs={ 4 }>
            <a href="https://www.linkedin.com/company/roamingaudit-com" title="Roamingaudit at LinkedIn" rel="noopener noreferrer" target="_blank">
              <img src="/react/images/auth/icon-l.png" alt="Roamingaudit at LinkedIn"/>
            </a>
          </Col>
        </Row>
    }
  </Col>
</Row>;

Header.propTypes = {
  taste: PropTypes.oneOf([TastesEnum.GUEST, TastesEnum.CLIENT]).isRequired
};

export default Header;
