import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faPlus, faTasks } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';
import { ConfirmationModal, withLoader, withModals, withNotifications } from '@roamingaudit/react-ui-utils';

import ElementSelector from '../../../components/ElementSelector/ElementSelector';
import axios from '../../../services/axios';
import TemplateTable from './TemplateTable/TemplateTable';
import Header from '../../../components/TabSelector/Header/Header';
import GenerateTemplateModal from './GenerateTemplateModal/GenerateTemplateModal';
import ModifyEntryModal from '../ModifyEntryModal/ModifyEntryModal';
import CharsRestrictionModal from './CharsRestrictionModal/CharsRestrictionModal';

import classes from './TemplatesView.module.css';

class TemplatesView extends Component {
  state = {
    anres: [],
    chars: [],
    template: null,
    columns: null,
    currentVendor: null,
    currentType: null,
    currentSubtype: null,
    filteredQty: 0,
  };

  async elementSelectionHandler(vendor, type, subtype) {
    this.setState({
      currentVendor: vendor,
      currentType: type,
      currentSubtype: subtype,
      template: null,
      filteredQty: 0,
      columns: null
    });

    if (vendor === null) {
      return;
    }

    this.props.showLoader();

    try {
      const response = await this.loadTemplateData(vendor, type, subtype);

      this.setState({ ...response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async loadTemplateData(vendor, type, subtype) {
    try {
      return await axios.get('/templates/' + [ vendor.id, type.id, subtype.id ].join('/'));
    } catch (e) {
      if (e.response && e.response.status === 404) {
        return Promise.resolve(e.response);
      }

      return Promise.reject(e);
    }
  }

  compareWithRoamingProfile() {
    if (this.state.currentSubtype.name === 'ROAMING_AREA') {
      this.props.showModal(
        CharsRestrictionModal,
        { submitActionHandler: this.runComparison.bind(this) }
      );
      return;
    }

    this.runComparison();
  };

  runComparison(data) {
    this.props.hideModal();

    const queryParts = [];
    if (data && data.include) {
      queryParts.push('charsIncl=' + data.include);
    }

    if (data && data.exclude) {
      queryParts.push('charsExcl=' + data.exclude);
    }

    const location = {
      pathname: [
        '/templates/compare',
        this.state.currentVendor.id,
        this.state.currentType.id,
        this.state.currentSubtype.id
      ].join('/')
    };

    const search = queryParts.join('&');
    if (search.length > 0) {
      location.search = '?' + search;
    }
    this.props.history.push(location);
  }

  async fetchNetworkElements() {
    this.props.showLoader();

    try {
      const response = await axios.get([
        '/templates',
        this.state.currentVendor.id,
        this.state.currentType.id,
        this.state.currentSubtype.id,
        'network-elements'
      ].join('/'));

      this.props.showModal(GenerateTemplateModal, {
        onSelectNetworkElement: this.generateFromNetworkElement.bind(this),
        columns: response.data.columns,
        rows: response.data.rows
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async modifyEntry(data, row) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      if (data.anres) {
        data.anres = JSON.stringify(data.anres);
      }

      const method = row ? 'put' : 'post';

      await axios[method](
        [
          '/templates',
          this.state.currentVendor.id,
          this.state.currentType.id,
          this.state.currentSubtype.id,
          'entry'
        ].join('/'),
        { entry: data }
      );

      const response = await this.loadTemplateData(
        this.state.currentVendor, this.state.currentType, this.state.currentSubtype
      );

      this.setState({ ...response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async startEntryModifications(row = null, isEditing = false) {
    this.props.showLoader();

    try {
      const response = await axios.get(
        [
          '/templates',
          this.state.currentVendor.id,
          this.state.currentType.id,
          this.state.currentSubtype.id,
          'entry-fields'
        ].join('/')
      );

      this.props.showModal(ModifyEntryModal, {
        isEditing,
        anresFields: this.state.anres,
        entryFields: response.data,
        existingEntry: row,
        submitActionHandler: this.modifyEntry.bind(this),
        chars: this.state.chars
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async generateFromNetworkElement(row) {
    this.props.showLoader();

    this.props.hideModal();

    try {
      await axios.post('/templates/' + row.id);

      const response = await this.loadTemplateData(this.state.currentVendor, this.state.currentType, this.state.currentSubtype);

      this.setState({ ...response.data, });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async removeRow(removingRow) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.delete([
        '/templates',
        this.state.currentVendor.id,
        this.state.currentType.id,
        this.state.currentSubtype.id,
        'entry',
        removingRow.id
      ].join('/'));

      const response = await this.loadTemplateData(this.state.currentVendor, this.state.currentType, this.state.currentSubtype);

      this.setState({ ...response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  filterHandler(qty) {
    if (this.state.filteredQty !== qty) {
      this.setState({ filteredQty: qty });
    }
  }

  render() {
    return <>
      <ElementSelector
        lazy={ true }
        elementsUrl="/elements/template-enabled"
        templateEnabledOnly={ true }
        elementChanged={ this.elementSelectionHandler.bind(this) }
        firstName="subtype"
        secondName="type"
        thirdName="vendor">
        {
          this.state.currentSubtype && this.state.currentType && this.state.currentVendor
            ? <Header>
              <div className={ classes.headerElement }>
                Filtered: { this.state.filteredQty }
              </div>
              <div className={ classes.headerElement }>
                <Button
                  className={ classes.headerButton }
                  variant="primary"
                  size="sm"
                  title="Compare with Roaming Profile"
                  onClick={ this.compareWithRoamingProfile.bind(this) }>
                  <FontAwesomeIcon icon={ faTasks }/> Compare with Roaming Profile
                </Button>
                <Button
                  className={ classes.headerButton }
                  variant="primary"
                  size="sm"
                  title="Generate Template"
                  onClick={ this.fetchNetworkElements.bind(this) }>
                  <FontAwesomeIcon icon={ faCogs }/> Generate Template
                </Button>
                <Button
                  className={ classes.headerButton }
                  variant="primary"
                  title="Add Entry"
                  size="sm"
                  onClick={ () => this.startEntryModifications() }>
                  <FontAwesomeIcon icon={ faPlus }/> Add Entry
                </Button>
              </div>
            </Header>
            : null
        }
      </ElementSelector>
      {
        this.state.columns && this.state.template
          ? <TemplateTable
            onFilter={ this.filterHandler.bind(this) }
            columns={ this.state.columns }
            rows={ this.state.template }
            editRow={ this.startEntryModifications.bind(this) }
            removeRow={ row => this.props.showModal(ConfirmationModal, {
              question: "Do you want to remove this row?",
              onYes: this.removeRow.bind(this, row)
            }) }/>
          : null
      }
    </>;
  }
}

export default withLoader(withNotifications(withModals(withRouter(TemplatesView))));
