import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_API_HOST
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = document.head.querySelector('meta[name="csrf-token"]');

instance.interceptors.response.use(
  response => response,
  error => {
    if (401 === error.response.status) {
      document.location.pathname = '/sign-out';
    } else {
      return Promise.reject(error);
    }
  }
);

if (token) {
  instance.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

export default instance;
