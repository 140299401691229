import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { Modal, ButtonSpinner } from '@roamingaudit/react-ui-utils';
import { useFormik } from 'formik';

const CharsRestrictionModal = props => {
  const form = createRef();
  const formik = useFormik({
    initialValues: {},
    onSubmit: props.submitActionHandler
  });

  return <Modal
    title="CHARS restrictions"
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>Cancel</Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting }
                form="chars-restriction-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Compare
        </Button>
      </>
    }
    hideModal={ props.onClose }>
    <Form noValidate
          id="chars-restriction-form"
          ref={ form }
          onSubmit={ formik.handleSubmit }>
      <Form.Group>
        <Form.Label>Must include:</Form.Label>
        <Form.Control
          type="text"
          name="include"
          value={ formik.values.include }
          onChange={ formik.handleChange }/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Must exclude:</Form.Label>
        <Form.Control
          type="text"
          name="exclude"
          value={ formik.values.exclude }
          onChange={ formik.handleChange }/>
      </Form.Group>
    </Form>
  </Modal>;
}

CharsRestrictionModal.propTypes = {
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default CharsRestrictionModal;
