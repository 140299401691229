import { LiteTable } from '@roamingaudit/react-lite-table';
import React from 'react';

const SummariesTable = props => <LiteTable
  highlight={ props.highlight }
  onRowClick={ props.onRowClick }
  columns={ props.columns }
  rows={ props.rows }
  customTableStyle={ { border: '1px solid #ddd' } }/>;

export default SummariesTable;
