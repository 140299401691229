import { Form } from 'react-bootstrap';
import React from 'react';

const PerPage = ({ current, onChange }) => (
  <Form.Control
    as="select"
    name="perPage"
    value={ current }
    onChange={ e => onChange(e.target.value) }>
    <option value="50">50</option>
    <option value="100">100</option>
    <option value="500">500</option>
  </Form.Control>
)

export default PerPage
