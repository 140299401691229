import React from 'react';

import Page from './Page';

const generatePages = (from, to, selectedPage, onChange) => {
  return Array.from({ length: 1 + to - from }, (_, i) => i + from).map(
    num => <Page onChange={ onChange }
                 key={ num }
                 i={ num }
                 isDisabled={ num.toString() === selectedPage.toString() }/>
  )
}

const Paginator = ({ pages, current, onChange }) => {
  if (pages < 4) {
    return <div>{ generatePages(1, pages, current, onChange) }</div>
  }

  const next = current < 4 && current > 1 ? current + 1 : 2
  let paginated = generatePages(1, next, current, onChange)

  if (current > 3 && current < pages - 3) {
    paginated.push(<Page onChange={ onChange } key="...1" i="..." isDisabled/>)
    paginated = paginated.concat(generatePages(current - 1, current + 1, current, onChange))
    paginated.push(<Page onChange={ onChange } key="...2" i="..." isDisabled/>)
  } else {
    paginated.push(<Page onChange={ onChange } key="...0" i="..." isDisabled/>)
  }

  const prev = current > pages - 4 && current < pages ? current - 1 : pages - 1
  paginated = paginated.concat(generatePages(prev, pages, current, onChange))

  return <div>{ paginated }</div>
}

export default Paginator
