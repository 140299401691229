import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Modal } from '@roamingaudit/react-ui-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

import classes from './PartnerFlagsModal.module.css'

const PartnerFlagsModal = ({ onConfirm, onRemove, hideModal, flagsProvider }) => {
  const [flags, setFlags] = useState(flagsProvider())
  const schema = yup.object({
    name: yup.string().matches(
      /^[a-z\d_]+$/im,
      'Should may only contain alphanumeric chars and underscore sign.')
      .required('This field is a obligatory.'),
  })

  const formik = useFormik({
    validationSchema: schema,
    validateOnMount: true,
    initialValues: {
      name: '',
    },
    onSubmit: async function (values, formikHelpers) {
      await onConfirm(values.name)
      setFlags(flagsProvider())
      formikHelpers.resetForm()
    }
  })
  /*            <Button variant="primary" className={ classes.button }>
              <FontAwesomeIcon icon={ faPencil }/>
            </Button>*/

  return <Modal
    actions={ <Button variant="secondary" onClick={ hideModal }>Done</Button> }
    hideModal={ hideModal }
    title="Partner Flags Definitions">
    {
      flags.map(flag => (
        <Row className={ classes.flagRow }
             key={ flag.id }>
          <Col xs>{ flag.flag }</Col>
          <Col xs="auto" className={ classes.buttons }>
            <Button variant="outline-secondary"
                    className={ classes.button }
                    onClick={ () => onRemove(flag.id) && setFlags(flagsProvider()) }>
              <FontAwesomeIcon icon={ faXmark }/>
            </Button>
          </Col>
        </Row>)
      )
    }
    <Form
      noValidate
      id="partner-flags-form"
      onSubmit={ formik.handleSubmit }>
      <Form.Row>
        <InputGroup
          as={ Col }
          xs="12"
          controlId="form-name">
          <Form.Control
            as="input"
            name="name"
            rows={ 5 }
            value={ formik.values.name }
            onChange={ formik.handleChange }
            isValid={ !formik.errors.name }
            isInvalid={ !!formik.errors.name && formik.touched.name }
            placeholder="Name"/>
          <Button variant="primary"
                  type="submit"
                  form="partner-flags-form"
                  className={ classes.button }
                  disabled={ !formik.isValid }>
            <FontAwesomeIcon icon={ faCheck }/>
          </Button>
          <Form.Control.Feedback type="invalid">
            { formik.errors.name }
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Row>
    </Form>
  </Modal>
}

PartnerFlagsModal.propTypes = {
  flagsProvider: PropTypes.func,
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
}

export default PartnerFlagsModal
