import React, { Component } from 'react';
import { ConfirmationModal, withLoader, withModals, withNotifications } from '@roamingaudit/react-ui-utils';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import axios from '../../../services/axios';
import OperatorsTable from './OperatorsTable/OperatorsTable';
import TopPanel from '../../../components/Layout/TopPanel/TopPanel';
import connect from 'react-redux/lib/connect/connect';

class OperatorListView extends Component {
  state = {
    data: [],
    filteredQty: 0
  };

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    this.props.showLoader();

    try {
      const response = await this.loadOperators();

      this.setState({ ...response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async loadOperators() {
    return await axios.get('/operators/list');
  }

  filterHandler(qty) {
    if (this.state.filteredQty !== qty) {
      this.setState({ filteredQty: qty });
    }
  }

  async delete(id) {
    this.props.showLoader();

    try {
      await axios.delete(`/operators/${ id }`);

      const response = await this.loadOperators();

      this.setState({ ...response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideModal();

    this.props.hideLoader();
  }

  async edit(id) {
    this.props.history.push(`/operators/${ id }/edit`)
  }

  view(id) {
    this.props.history.push(`/operators/${ id }`)
  }

  async toggleStatus(id, status) {
    this.props.showLoader();

    try {
      await axios.put(`/operators/${ id }/status`, { status });

      const response = await this.loadOperators();

      this.setState({ ...response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  initModifications(id = null) {
    this.props.history.push(`/operators/${ id ?? 'create'}`)
  }

  async useOperator(id, name) {
    this.props.showLoader();

    try {
      await axios.put(`/operators/${ id }/use`);
      this.props.user.operator.id = id;
      this.props.user.operator.name = name;
      this.props.history.push('/configuration');
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  render() {
    return <>
      <Row noGutters>
        <Col xs={ true }/>
        <Col xs="auto">
          <Button
            id="configuration__add-button"
            size="sm"
            variant="success"
            onClick={ () => this.initModifications() }>
            <FontAwesomeIcon icon={ faPlus }/> Add Operator
          </Button>
        </Col>
      </Row>
      <OperatorsTable
        current={ this.props.user?.operator?.id }
        onFilter={ this.filterHandler.bind(this) }
        rows={ this.state.data }
        deleteOperator={ row => this.props.showModal(ConfirmationModal, {
          question: `Are you sure you want to delete operator ${ row.name } PERMANENTLY?`,
          onYes: this.delete.bind(this),
          data: row.id
        }) }
        editOperator={ this.initModifications.bind(this) }
        toggleOperator={ this.toggleStatus.bind(this) }
        viewOperator={ this.view.bind(this) }
        useOperator={ this.useOperator.bind(this) }/>
    </>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};

export default withModals(withNotifications(withLoader(withRouter(connect(mapStateToProps)(OperatorListView)))));
