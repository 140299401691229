import React from 'react';
import { LiteTable } from '@roamingaudit/react-lite-table';

const RowAnalysisTable = props => <LiteTable
  rows={ props.rows }
  columns={ props.columns }
  customContainerStyle={ {
    paddingRight: '20px',
    overflow: 'visible'
  } }/>

export default RowAnalysisTable;
