import React, { Component } from 'react';
import { withLoader, withNotifications } from '@roamingaudit/react-ui-utils';

import Aa14InboxTable from './Aa14InboxTable/Aa14InboxTable';
import axios from '../../../services/axios';

class Aa14InboxView extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    this.fetchInbox();
  }

  async fetchInbox() {
    this.props.showLoader();

    try {
      const response = await axios.get('/info/aa14/inbox');

      this.setState({ data: response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  render() {
    return <Aa14InboxTable rows={ this.state.data }/>;
  }
}

export default withLoader(withNotifications(Aa14InboxView));
