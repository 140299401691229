import axios from './axios';
import PollingWorker from './pollingWorkerSetup';

export default class ProgressService {
  progress = {};
  isRunning = false;
  progressNotifier;
  prefix;

  constructor(prefix, progressNotifier) {
    this.prefix = prefix;
    this.progressNotifier = progressNotifier;
  }

  run(requestConfig, processId) {
    const currentWorker = new PollingWorker();

    return this.startWorker(
      this.prefix + '-' + processId,
      currentWorker,
      requestConfig,
      this.progressUpdateHandler
    ).then(axiosResponse => {
       currentWorker.postMessage({ cmd: 'stop' });

       return axiosResponse;
    }).catch(e => {
       currentWorker.postMessage({ cmd: 'stop' });

      return Promise.reject(e);
    });
  }

  runChunks(requestConfig, chunkBy) {
    const chunkable = [ ...requestConfig.data[chunkBy] ];
    const numberOfIterations = Math.ceil(chunkable.length / 5);
    const numberOfItemsPerIteration = Math.ceil(chunkable.length / numberOfIterations);
    const requests = [];
    const workers = [];

    while (chunkable.length > 0) {
      const requestChunks = chunkable.splice(0, numberOfItemsPerIteration);
      const progressId = this.prefix + '-' + requestChunks.sort((a, b) => {
        a = parseInt(a, 10);
        b = parseInt(b, 10);
        if (a === b) {
          return 0;
        }

        return a < b ? -1 : 1;
      }).join('-');

      const currentWorker = new PollingWorker();
      currentWorker.onerror = this.onWorkerError.bind(this)
      currentWorker.onmessageerror = this.onWorkerError.bind(this)

      requests.push(this.startWorker(
        progressId,
        currentWorker,
        { ...requestConfig, data: { ...requestConfig.data, [chunkBy]: requestChunks }},
        this.progressChunksUpdateHandler
      ));

      workers.push(currentWorker);
    }

    return Promise.all(requests).then(() => {
      workers.forEach((currentWorker) => {
        currentWorker.postMessage({ cmd: 'stop' });
      });

      return null;
    }).catch((e) => {
      workers.forEach((currentWorker) => {
        currentWorker.postMessage({ cmd: 'stop' });
      });

      return Promise.reject(e);
    });
  }

  startWorker(progressId, worker, requestConfig, progressUpdateHandler) {
    this.progress[progressId] = 0;
    worker.addEventListener('message', progressUpdateHandler.bind(this, progressId), false);

    worker.postMessage({
      cmd: 'start',
      url: process.env.REACT_APP_API_HOST + '/v2/progress/' + progressId,
      payload: { hash: null }
    });

    return axios.request(requestConfig);
  }

  progressChunksUpdateHandler(progressId, event) {
    if (event.data.progress !== null) {
      this.progress[progressId] = parseInt(event.data.progress, 10);
      const progress = Object.entries(this.progress).reduce((currentValue, [progressId, progressValue]) => {
        return progressValue + currentValue;
      }, 0) / Object.keys(this.progress).length;

      this.progressNotifier(progress);
    }
  }

  progressUpdateHandler(progressId, event) {
    if (event.data.progress !== null) {
      this.progress[progressId] = parseInt(event.data.progress, 10);

      this.progressNotifier(this.progress[progressId]);
    }
  }

  onWorkerError(e) {
    console.log(e)
  }
}
