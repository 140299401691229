export default () => {
  function LongPolling () {
    var lp = this;

    lp.isPollingRunning = false;
    lp.pollingProcess = null;
    lp.delayInterval = null;

    lp.start = function(url, payload = {}, delaySec) {
      if (lp.isPollingRunning) {
        throw new Error("[LongPollingService] Polling is already running, stop it before running again.");
      }
      lp.isPollingRunning = true;
      if (delaySec) {
        console.log(`[LongPollingService] Waiting for ${ delaySec } to start`)
        lp.delayInterval = setTimeout(() => {
          lp.delayInterval = null;
          lp.poll(url, payload);
        }, delaySec * 1000);
      } else {
        console.log(`[LongPollingService] Starting immediately`)
        lp.poll(url, payload);
      }
    };

    lp.stop = function () {
      lp.isPollingRunning = false;

      if (lp.pollingProcess !== null) {
        return lp.pollingProcess.finally(() => {
          lp.pollingProcess = null;
        });
      }

      if (lp.delayInterval !== null) {
        clearTimeout(this.delayInterval);
      }

      return Promise.resolve(null);
    };

    lp.isRunning = function () {
      return lp.isPollingRunning;
    };

    lp.poll = function (url, payload) {
      if (!lp.isPollingRunning) {
        console.log(`[LongPollingService] Polling is not running. Breaking...`)
        return;
      }

      lp.pollingProcess = lp.performPollingRequest(url, payload)
        .then((response) => {
          return response.json();
        }).then((data) => {
          postMessage(data);
          setTimeout(() => {
            payload.hash = data.hash;
            lp.poll(url, payload);
          }, 0);
        }).catch((e) => {
          if (401 === e.status) {
            lp.stop();
          } else if (-1 === [504, 408, 520].indexOf(e.status)) {
            setTimeout(
              lp.poll.bind(self, url, payload),
              30 * 1000
            );
          } else {
            lp.poll(url, payload);
          }
        });
    };

    lp.performPollingRequest = function (url, payload) {
      console.log(`[LongPollingService] Performing a request.`)

      return fetch(
        url,
        {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
          credentials: 'omit'
        }
      );
    }
  }

  const LongPollingService = new LongPolling();
  let isStarted = false;
  onmessage = function(event) {
    console.log('[PollingWorker] a message was received', event)

    switch (event.data.cmd) {
      case 'start':
        console.log('[PollingWorker] Requested to start polling')
        if (!isStarted) {
          console.log('[PollingWorker] Starting polling')
          LongPollingService.start(event.data.url, event.data.payload);
          isStarted = true;
        }
        break;
      case 'stop':
        console.log('[PollingWorker] Requested to stop polling')
        if (isStarted) {
          console.log('[PollingWorker] Stopping polling')
          isStarted = false;
          LongPollingService.stop().then(function () {
            self.close()
          });
        }
        break;
    }
  }
};

