import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Modal, ButtonSpinner } from '@roamingaudit/react-ui-utils';

import ElementSelector from '../../../../components/ElementSelector/ElementSelector';

const NewConfigurationModal = props => {
  const form = createRef();
  const schema = yup.object({
    names: yup.string()
      .matches(
        /^(?:[0-9a-z_]+(?:,[a-z0-9_]+)*|[0-9a-z_]+(?: [a-z0-9_]+)*|[0-9a-z_]+(?:\n[a-z0-9_]+)*)$/i,
        'Should contain only letters, digits and underscore (use space OR new line OR comma as separator ).')
      .required('This field is a obligatory.')
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      subtype: null,
      type: null,
      vendor: null,
      names: ''
    },
    onSubmit: data => props.submitActionHandler(data.vendor, data.type, data.subtype, splitNames())
  });

  const splitNames = () => {
    return formik.values.names.indexOf(',') !== -1
      ? formik.values.names.split(',')
      : (
        formik.values.names.indexOf(' ') !== -1
          ? formik.values.names.split(' ')
          : formik.values.names.split('\n')
      );
  }

  const onElementChange = (subtype, vendor, type) => {
    formik.setFieldValue('subtype', subtype);
    formik.setFieldValue('vendor', vendor);
    formik.setFieldValue('type', type);
  }

  return <Modal
    id="configuration__add-ne-modal"
    size="sm"
    hideModal={ props.hideModal }
    title="Add Network Element"
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting || !formik.dirty || !formik.isValid }
                form="add-network-element-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Add
        </Button>
      </>
    }>
    <Form noValidate
          id="add-network-element-form"
          ref={ form }
          onSubmit={ formik.handleSubmit }>
      <Form.Label>Associated Element</Form.Label>
      <ElementSelector
        size="12"
        elementChanged={ onElementChange }
        thirdName="subtype"
        secondName="vendor"
        firstName="type"
        elementsUrl="/elements"/>
      <Form.Group controlId="add-network-element-form__names">
        <Form.Label>Node Names</Form.Label>
        <Form.Control
          as="textarea"
          name="names"
          rows={ 3 }
          value={ formik.values.names }
          onChange={ formik.handleChange }
          isValid={ formik.touched.names && !formik.errors.names }
          isInvalid={ !!formik.errors.names && !formik.touched.names }
          placeholder="Separators: coma OR new line OR space"/>
        <Form.Control.Feedback type="invalid">
          { formik.errors.names }
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
    <Row>
      <Col xs="12">
        NE Names To Be Added
      </Col>
    </Row>
    {
      formik.isValid
        ? splitNames().map(name => <Row><Col xs="12">{ name }</Col></Row>)
        : <Row><Col xs="12">-</Col></Row>
    }
  </Modal>;
};

NewConfigurationModal.propTypes = {
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default NewConfigurationModal;
