import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { Modal } from '@roamingaudit/react-ui-utils';

const AddExceptionModal = props => <Modal
  id="ResultsView__add-exception-modal"
  size="lg"
  hideModal={ props.hideModal }
  actions={
    props.matchingNetworkElements.length > 1
      ? <>
        <Button
          variant="warning"
          onClick={ () => props.submitActionHandler(true) }>
          All Similar
        </Button>
        <Button
          variant="primary"
          onClick={ () => props.submitActionHandler() }>
          Only This
        </Button>
      </>
      : <Button
        variant="primary"
        onClick={ () => props.submitActionHandler() }>
        Yes, I do
      </Button>
  }
  title="Add an entry to the exceptions">
  {
    props.matchingNetworkElements.length > 1
      ? <>
        <p>
          Do you want to add an exception for this network element only or to all network elements
          with the same vendor-type-subtype combination?
        </p>
        <p>
          Matching Network Elements:
        </p>
        <ul>
          {
            props.matchingNetworkElements.map(ne => <li>{ ne }</li>)
          }
        </ul>
      </>
      : <p>Do you want to add an exception for this network element?</p>
  }
</Modal>;

AddExceptionModal.propTypes = {
  matchingNetworkElements: PropTypes.array.isRequired,
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default AddExceptionModal;
