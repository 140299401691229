import React from 'react';
import { LiteTable } from '@roamingaudit/react-lite-table';
import { Button } from 'react-bootstrap';
import { faCode, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './Aa14InboxTable.module.css';

const Aa14InboxTable = props => {
  const actions = row => {
    return [
      <Button
        variant="primary"
        className={ classes.actionButton }
        size="sm"
        target="_blank"
        href={ `/info/aa14/xml/${ row.hash }/${ row.document_name }` }>
        <FontAwesomeIcon icon={ faCode }/>
      </Button>,
      <Button
        variant="primary"
        className={ classes.actionButton }
        size="sm"
        target="_blank"
        href={ `/info/aa14/pdf/${ row.hash }/${ row.document_name }` }>
        <FontAwesomeIcon icon={ faFilePdf }/>
      </Button>
    ];
  }

  return <LiteTable
    rows={ props.rows }
    actions={ actions }
    initialSorting={ {
      column: 'publish_date',
      reversed: true
    } }
    columnsMap={ {
      'distribution_type': 'Distribution Type', 'document_name': 'Document Name', 'document_type': 'Document Type',
      'publish_date': 'Publish Date', 'receiver': 'Receiver', 'sender': 'Sender', 'tadig': 'Tadig', 'vpmn': 'Vpmn'
    } }
    withFiltering
    withSorting/>
};

export default Aa14InboxTable;
