import React, { Component, createRef } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import {
  ButtonSpinner,
  NotificationSeverity,
  withLoader,
  withModals,
  withNotifications
} from '@roamingaudit/react-ui-utils'
import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import * as yup from 'yup'

import Switch from './Switch/Switch'
import GroupSwitch from './GroupSwitch/GroupSwitch'
import RawSwitch from './RawSwitch/RawSwitch'
import DropdownSearch from '../../../components/DropdownSearch/DropdownSearch'
import axios from '../../../services/axios'
import PartnerFlagsModal from './PartnerFlagsModal/PartnerFlagsModal'

import classes from './EditOperatorView.module.css'
import ApplyFlagsModal from './ApplyFlagsModal/ApplyFlagsModal'

class EditOperatorView extends Component {
  state = {
    operator: {
      name: '',
      country: null,
      ir21: null,
      status: true,
      trial_include_regions: false,
      sim_management: false,
      sim_management_pin: false,
      sim_management_import: false,
      partner_document_upload: false,
      roaming_partner_flags: false,
      api_tadig: null,
      api_login: null,
      api_password: null,
      api_ir21_key: null,
      api_aa14_key: null,
      api_ir21_data_source: 'inbox'
    },
    blockedMenus: [],
    countries: [],
    ir21: [],
    isMenuIr21Checked: false,
    isMenuPartnerManagerChecked: false,
    partnerFlags: null
  }

  form = createRef()
  schema = null

  constructor(props) {
    super(props)

    this.schema = yup.object({
      operator: yup.object({
        name: yup.string()
          .matches(/^[a-z0-9!?,+*:.()/& \-]+$/i, 'Allowed characters are letters, digits, space and !?,+*:.()/&-')
          .max(255, 'Should be up to 255 characters long.')
          .ensure()
          .required('Operator name is a required field.'),
        country: yup.number().nullable(true).default(null),
        ir21: yup.number().transform(function (value) {
          return this.isType(value) ? value : value.id
        }).nullable(true).default(null),
        status: yup.boolean().default(true).required(),
        trial_include_regions: yup.boolean().default(false),
        sim_management: yup.boolean().default(false),
        sim_management_import: yup.boolean().default(false),
        sim_management_pin: yup.boolean().default(false),
        partner_document_upload: yup.boolean().default(false),
        roaming_partner_flags: yup.boolean().default(false),
        api_tadig: yup.string()
          .matches(/^[a-z0-9]+$/i, 'TADIG should consist of exactly 5 alphanumeric characters.')
          .min(5, 'Too short. TADIG should contain exactly 5 alphanumeric characters.')
          .max(5, 'Too long. TADIG should contain exactly 5 alphanumeric characters.')
          .nullable(true).default(null),
        api_login: yup.string()
          .email('API Login should be represented with a valid email.')
          .min(5, 'API Login should be at least 5 characters long.')
          .max(255, 'API Login should be max 255 characters long.')
          .nullable(true).default(null),
        api_password: yup.string()
          .matches(/^[a-z0-9]+$/i, 'API Password should consist of exactly 12 alphanumeric characters.')
          .min(12, 'Too short. API Password should consist of exactly 12 alphanumeric characters.')
          .max(12, 'Too long. API Password should consist of exactly 12 alphanumeric characters.')
          .nullable(true).default(null),
        api_ir21_key: yup.string()
          .matches(/^[a-z0-9]+$/i, 'IR.21 API Key should consist of exactly 48 alphanumeric characters.')
          .min(48, 'Too short. IR.21 API Key should consist of exactly 48 alphanumeric characters.')
          .max(48, 'Too long. IR.21 API Key should consist of exactly 48 alphanumeric characters.')
          .nullable(true).default(null),
        api_aa14_key: yup.string()
          .matches(/^[a-z0-9]+$/i, 'AA.14 API Key should consist of exactly 48 alphanumeric characters.')
          .min(48, 'Too short. AA.14 API Key should consist of exactly 48 alphanumeric characters.')
          .max(48, 'Too long. AA.14 API Key should consist of exactly 48 alphanumeric characters.')
          .nullable(true).default(null),
        api_ir21_data_source: yup.string().oneOf([ 'inbox', 'archive' ]).default('inbox')
      }),
      blockedMenus: yup.array().ensure().default([])
    })
  }

  componentDidMount() {
    this.preload()
  }

  async preload() {
    await this.fetchIr21AndCountries()
    if (this.props.match.params.id) {
      this.fetchOperator()
    }
  }

  async fetchIr21AndCountries() {
    this.props.showLoader()

    try {
      const ir21 = await axios.get('/operators/ir21')
      const countries = await axios.get('/operators/countries')

      this.setState({ ir21: ir21.data, countries: countries.data })
    } catch (e) {
      this.props.processError(e)
    }

    this.props.hideLoader()
  }

  async fetchOperator() {
    this.props.showLoader()

    try {
      const response = await axios.get(`/operators/${ this.props.match.params.id }/details`)
      const operator = { ...response.data.operator }
      if (operator.ir21) {
        operator.ir21 = this.state.ir21.find(ir21 => ir21.id === operator.ir21) ?? null
      }

      this.setState({ operator, blockedMenus: response.data.blockedMenus })
    } catch (e) {
      this.props.processError(e)
    }

    this.props.hideLoader()
  }

  async removePartnerFlag(id) {
    this.props.showLoader()

    try {
      await axios.delete(`/operators/partner-flags/${ id }`)

      const { data } = await this.fetchPartnerFlags()

      this.setState({ partnerFlags: data.flags })
    } catch (e) {
      this.props.processError(e)
    }

    this.props.hideLoader()
  }

  fetchPartnerFlags() {
    return axios.get(`/operators/${ this.props.match.params.id }/partner-flags`)
  }

  async showPartnerFlagsModal() {
    this.props.showLoader()

    try {
      const { data } = await this.fetchPartnerFlags()

      this.setState({ partnerFlags: data.flags }, () => {
        if (this.state.partnerFlags === null) {
          return
        }

        this.props.showModal(PartnerFlagsModal, {
          onRemove: this.removePartnerFlag.bind(this),
          onConfirm: this.savePartnerFlag.bind(this),
          flagsProvider: () => this.state.partnerFlags
        })
      })

    } catch (e) {
      this.props.processError(e)
    }

    this.props.hideLoader()
  }

  async showPartnerFlagsToTadigsModal() {
    this.props.showLoader()

    try {
      const { data } = await axios.get(`/operators/${ this.props.match.params.id }/partner-flags/tadigs`)

      this.props.showModal(ApplyFlagsModal, {
        onConfirm: this.updatePartnerFlagsTadigsMap.bind(this),
        tadigsMap: data.tadigsMap
      })
    } catch (e) {
      this.props.processError(e)
    }

    this.props.hideLoader()
  }

  async updatePartnerFlagsTadigsMap(flagId, tadigs) {
    this.props.showLoader()

    try {
      await axios.post(
        `/operators/${ this.props.match.params.id }/partner-flags/${ flagId }/tadigs`,
        {'tadigs': tadigs.split("\n")}
      )
    } catch (e) {
      this.props.processError(e)
    }

    this.props.hideLoader()
  }

  async savePartnerFlag(flag) {
    this.props.showLoader()

    try {
      await axios.post(`/operators/${ this.props.match.params.id }/partner-flags`, { name: flag })

      const { data } = await this.fetchPartnerFlags()

      this.setState({ partnerFlags: data.flags })
    } catch (e) {
      this.props.processError(e)
    }

    this.props.hideLoader()
  }

  async save(values) {
    this.props.showLoader()

    try {
      const operator = { ...values.operator, ir21: values.operator?.ir21?.id ?? null }
      const payload = { operator, blockedMenus: values.blockedMenus }

      this.props.match.params.id
        ? await axios.put(`/operators/${ this.props.match.params.id }`, payload)
        : await axios.post('/operators', payload)

      this.props.notify(NotificationSeverity.SUCCESS, 'Operator data was saved successfully.', 10, true)

      this.props.history.push('/operators')
    } catch (e) {
      this.props.processError(e)
    }

    this.props.hideLoader()
  }


  render() {
    return <Formik
      enableReinitialize
      initialValues={ { operator: this.state.operator, blockedMenus: this.state.blockedMenus } }
      validationSchema={ this.schema }
      onSubmit={ this.save.bind(this) }>
      {
        formik =>
          <Form
            noValidate
            id="operator-modal-form"
            ref={ this.form }
            onSubmit={ formik.handleSubmit }>
            <Container>
              <Row>
                <Col xs="12" className={ classes.bottomPanelColumn }>
                  <RawSwitch
                    className="m-0"
                    label="Is Active"
                    name="operator.status"
                    onChange={ formik.handleChange }
                    isChecked={ formik.values.operator.status }
                    id="operator-status"/>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={ formik.isSubmitting }
                    form="operator-modal-form">
                    { formik.isSubmitting ? <ButtonSpinner/> : null } Save
                  </Button>
                </Col>
              </Row>
              <Row className="pb-5">
                <Col xs="6">
                  <h4>Profile</h4>
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      name="operator.name"
                      value={ formik.values.operator.name }
                      onChange={ formik.handleChange }
                      placeholder="Name"
                      isValid={ formik.touched?.operator?.name && !formik.errors?.operator?.name }
                      isInvalid={ !!formik.errors?.operator?.name }/>
                    <Form.Control.Feedback type="invalid">
                      { formik.errors?.operator?.name }
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      name="operator.country"
                      value={ formik.values.operator.country }
                      onChange={ formik.handleChange }>
                      <option value="" selected>Select Country</option>
                      {
                        this.state.countries.map(country => <option value={ country.id }>{ country.name }</option>)
                      }
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="ir21">
                    <Form.Label>IR.21</Form.Label>
                    <DropdownSearch
                      current={ formik.values.operator.ir21 }
                      dictionary={ this.state.ir21 }
                      name="operator.ir21"
                      emptyDropdownTitle="Select IR.21"
                      changed={ val => formik.setFieldValue('operator.ir21', val) }
                      uniquePrefix="OperatorModal__ir21-selectbox"/>
                  </Form.Group>
                </Col>
                <Col xs="6">
                  <h4>Features</h4>
                  <RawSwitch
                    label="Enable Sim Management"
                    name="operator.sim_management"
                    isChecked={ formik.values.operator.sim_management }
                    onChange={ formik.handleChange }
                    id="sim_management"/>
                  <RawSwitch
                    label="Enable Sim Management Import"
                    name="operator.sim_management_import"
                    isChecked={ formik.values.operator.sim_management_import }
                    onChange={ formik.handleChange }
                    id="sim_management_import"/>
                  <RawSwitch
                    label="Enable Sim Management PIN2 and PUK2"
                    name="operator.sim_management_pin"
                    isChecked={ formik.values.operator.sim_management_pin }
                    onChange={ formik.handleChange }
                    id="sim_management_pin"/>
                  <RawSwitch
                    label="Include Regions for Trial"
                    name="operator.trial_include_regions"
                    isChecked={ formik.values.operator.trial_include_regions }
                    onChange={ formik.handleChange }
                    id="trial_include_regions"/>
                  <RawSwitch
                    label="Enable Partner Document Upload"
                    name="operator.partner_document_upload"
                    isChecked={ formik.values.operator.partner_document_upload }
                    onChange={ formik.handleChange }
                    id="partner_document_upload"/>
                  <RawSwitch
                    label="Enable Roaming Partner Flags"
                    name="operator.roaming_partner_flags"
                    isChecked={ formik.values.operator.roaming_partner_flags }
                    onChange={ formik.handleChange }
                    id="roaming_partner_flags"/>
                  {
                    !!formik.values.operator.roaming_partner_flags && <div className={ classes.partnerFlagButtons }>
                      <Button size="sm" variant="primary" onClick={ this.showPartnerFlagsModal.bind(this) }>Define
                        Flags</Button>
                      <Button size="sm" variant="primary" onClick={ this.showPartnerFlagsToTadigsModal.bind(this) }>Apply
                        Flags</Button>
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h4>GT Tool API</h4>
                </Col>
              </Row>
              <Row className="pb-5">
                <Col xs="6">
                  <Form.Group controlId="api_tadig">
                    <Form.Label>API TADIG</Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      name="operator.api_tadig"
                      value={ formik.values.operator.api_tadig }
                      onChange={ formik.handleChange }
                      isValid={ formik.touched?.operator?.api_tadig && !formik.errors?.operator?.api_tadig }
                      isInvalid={ !!formik.errors?.operator?.api_tadig }
                      placeholder="API TADIG"/>
                    <Form.Control.Feedback type="invalid">
                      { formik.errors?.operator?.api_tadig }
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="api_login">
                    <Form.Label>API Login</Form.Label>
                    <Form.Control
                      type="email"
                      autocomplete="off"
                      name="operator.api_login"
                      value={ formik.values.operator.api_login }
                      onChange={ formik.handleChange }
                      isValid={ formik.touched?.operator?.api_login && !formik.errors?.operator?.api_login }
                      isInvalid={ !!formik.errors?.operator?.api_login }
                      placeholder="API Login"/>
                    <Form.Control.Feedback type="invalid">
                      { formik.errors?.operator?.api_login }
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="api_password">
                    <Form.Label>API Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="operator.api_password"
                      value={ formik.values.operator.api_password }
                      onChange={ formik.handleChange }
                      isValid={ formik.touched?.operator?.api_password && !formik.errors?.operator?.api_password }
                      isInvalid={ !!formik.errors?.operator?.api_password }
                      placeholder="API Password"/>
                    <Form.Control.Feedback type="invalid">
                      { formik.errors?.operator?.api_password }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs="6">
                  <Form.Group controlId="api_ir21_key">
                    <Form.Label>IR.21 API KEY</Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      name="operator.api_ir21_key"
                      value={ formik.values.operator.api_ir21_key }
                      onChange={ formik.handleChange }
                      isValid={ formik.touched?.operator?.api_ir21_key && !formik.errors?.operator?.api_ir21_key }
                      isInvalid={ !!formik.errors?.operator?.api_ir21_key }
                      placeholder="IR.21 API KEY"/>
                    <Form.Control.Feedback type="invalid">
                      { formik.errors?.operator?.api_ir21_key }
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="api_aa14_key">
                    <Form.Label>AA.14 API KEY</Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      name="operator.api_aa14_key"
                      value={ formik.values.operator.api_aa14_key }
                      onChange={ formik.handleChange }
                      isValid={ formik.touched?.operator?.api_aa14_key && !formik.errors?.operator?.api_aa14_key }
                      isInvalid={ !!formik.errors?.operator?.api_aa14_key }
                      placeholder="AA.14 API KEY"/>
                    <Form.Control.Feedback type="invalid">
                      { formik.errors?.operator?.api_aa14_key }
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="api_ir21_data_source">
                    <Form.Label>IR.21 Data Source</Form.Label>
                    <Form.Control
                      as="select"
                      name="operator.api_ir21_data_source"
                      value={ formik.values.operator.api_ir21_data_source || 'inbox' }
                      onChange={ formik.handleChange }>
                      <option value="inbox">Inbox</option>
                      <option value="archive">Archive</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h4>Menu Access</h4>
                </Col>
              </Row>
              <Row className="pb-5">
                <Col xs="4">
                  <GroupSwitch
                    menus={ [ 'Coverage Map', 'Roaming Partners', 'Roaming Profile' ] }
                    formik={ formik }
                    label={ <h5>Partner Management</h5> }
                    id="menu_partner_manager"/>
                  <Switch label="Coverage Map" formik={ formik } id="menu_coverage_map"/>
                  <Switch label="Roaming Partners" formik={ formik } id="menu_roaming_partners"/>
                  <Switch label="Roaming Profile" formik={ formik } id="menu_roaming_profile"/>
                  <GroupSwitch
                    menus={ [ 'Collaboration', 'Sim Management', 'Test Scheduling' ] }
                    formik={ formik }
                    label={ <h5>Coordination Management</h5> }
                    id="menu_coordination"/>
                  <Switch label="Collaboration" formik={ formik } id="menu_collaboration"/>
                  <Switch label="Sim Management" formik={ formik } id="menu_sim_management"/>
                  <Switch label="Test Scheduling" formik={ formik } id="menu_test_scheduling"/>
                  <GroupSwitch
                    menus={ [ 'SS7 Security Audit', 'IP Security Audit', 'Perfect Filter' ] }
                    formik={ formik }
                    label={ <h5>Security Management</h5> }
                    id="menu_security"/>
                  <Switch label="SS7 Security Audit" formik={ formik } id="menu_ss7_security"/>
                  <Switch label="IP Security Audit" formik={ formik } id="menu_ip_security"/>
                  <Switch label="Perfect Filter" formik={ formik } id="menu_perfect_filter"/>
                </Col>
                <Col xs="4">
                  <GroupSwitch
                    menus={ [
                      'IR.21 Dashboard', 'IR.21 Database', 'IR.21 Exporting', 'IR.21 Updates', 'Global Title',
                      'Packet Data', 'IR.21 Inbox', 'AA.14 Inbox'
                    ] }
                    formik={ formik }
                    label={ <h5>IR.21 Management</h5> }
                    id="menu_ir21"/>
                  <Switch label="IR.21 Dashboard" formik={ formik } id="menu_ir21_dashboard"/>
                  <Switch label="IR.21 Database" formik={ formik } id="menu_ir21_database"/>
                  <Switch label="IR.21 Exporting" formik={ formik } id="menu_ir21_exporting"/>
                  <Switch label="IR.21 Updates" formik={ formik } id="menu_ir21_updates"/>
                  <Switch label="Global Title" formik={ formik } id="menu_global_title"/>
                  <Switch label="Packet Data" formik={ formik } id="menu_packet_data"/>
                  <Switch label="IR.21 Inbox" formik={ formik } id="menu_ir21_inbox"/>
                  <Switch label="AA.14 Inbox" formik={ formik } id="menu_aa14_inbox"/>
                </Col>
                <Col xs="4">
                  <GroupSwitch
                    menus={ [
                      'Dashboard', 'Group Dashboard', 'Network Elements', 'Node Templates', 'Audit Results',
                      'Reports', 'Run Audits'
                    ] }
                    formik={ formik }
                    label={ <h5>Configuration Management</h5> }
                    id="menu_configuration"/>
                  <Switch label="Dashboard" formik={ formik } id="menu_dashboard"/>
                  <Switch label="Group Dashboard" formik={ formik } id="menu_group_dashboard"/>
                  <Switch label="Network Elements" formik={ formik } id="menu_network_elements"/>
                  <Switch label="Node Templates" formik={ formik } id="menu_node_templates"/>
                  <Switch label="Audit Results" formik={ formik } id="menu_audit_results"/>
                  <Switch label="Reports" formik={ formik } id="menu_reports"/>
                  <Switch label="Run Audits" formik={ formik } id="menu_run_audits"/>
                  <Switch label="Number Range Optimizer" formik={ formik } id="menu_number_range_optimizer"/>
                </Col>
              </Row>
            </Container>
          </Form>
      }
    </Formik>
  }
}

export default withModals(withNotifications(withLoader(withRouter(EditOperatorView))))
