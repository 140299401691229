import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ReactReduxContext } from 'react-redux';
import Row from 'react-bootstrap/Row';
import { LoaderRoot, NotificationsRoot } from '@roamingaudit/react-ui-utils';

import Menu from './Menu/Menu';
import Header from './Header/Header';
import TopPanel from './TopPanel/TopPanel';
import Footer from './Footer/Footer';
import TastesEnum from './TastesEnum';

import classes from './Layout.module.css';
import PropTypes from "prop-types";

const Layout = props => <Container fluid={ true } className={ classes.wrapper }>
  <Header taste={ props.taste }/>
  <Row className={ classes.mainContainerWrapper + ' flex-nowrap' }>
    {
      props.taste === TastesEnum.CLIENT
        ? <Col xs="auto" className={ classes.menuSidebar + ' p-0' }>
          <Menu context={ ReactReduxContext }/>
        </Col>
        : null
    }
    <Col className={ classes.mainContainer + (props.taste === TastesEnum.GUEST ? ' ' + classes['mainContainer--guest'] : '') }>
      {
        props.taste === TastesEnum.CLIENT
          ? <TopPanel context={ ReactReduxContext }/>
          : null
      }
      <NotificationsRoot/>
      <div className={ props.taste === TastesEnum.GUEST ? classes.authWidgetWrapper : classes.contentWrapper }>
        { props.children }
      </div>
    </Col>
  </Row>
  <Footer taste={ props.taste }/>
  <LoaderRoot/>
</Container>;

Layout.propTypes = {
  taste: PropTypes.oneOf([ TastesEnum.GUEST, TastesEnum.CLIENT ]).isRequired
};

export default Layout;
