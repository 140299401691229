import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Modal } from '@roamingaudit/react-ui-utils';
import { LiteTable } from '@roamingaudit/react-lite-table';

const ReviewModal = props => {
  let titleConstraint = `length ${ props.valueLength }`;

  if (props.query && props.valueLength === null) {
    let modePhrase;
    if (props.query.subtype === 'IMSI') {
      modePhrase = props.query.mode === 'keep' ? 'does not start with' : 'starts with';
    } else {
      modePhrase = props.query.mode === 'keep' ? 'does not contain' : 'contains';
    }

    titleConstraint = `${modePhrase} ${ props.query.values.join(', ') }`;
  }

  return <Modal
    size="lg"
    hideModal={ props.hideModal }
    actions={
      <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
    }
    title={ `${ props.networkElement } (${ titleConstraint }): ${ props.data.rows.length } Entries` }>
    <LiteTable columns={ props.data.columns } rows={ props.data.rows }/>
  </Modal>
}

ReviewModal.propTypes = {
  networkElement: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  valueLength: PropTypes.number.isRequired,
  query: PropTypes.object
};

export default ReviewModal;
