import React from 'react';
import { LiteTable } from '@roamingaudit/react-lite-table';

const ResultsTable = props => <LiteTable
  rows={ props.rows }
  onFilter={ props.onFilter }
  columnsMap={ {
    'organization': 'Organization',
    'brand': 'Brand',
    'country': 'Country',
    'tadig': 'TADIG',
    'hub': 'Hub',
    'mcc': 'MCC',
    'mnc': 'MNC',
    'cc': 'CC',
    'ndc': 'NDC',
    'rangeStart': 'Start',
    'rangeStop': 'Stop',
    'newCcNdc': 'New CC+NDC',
    'newRangeStart': 'New Start',
    'newRangeStop': 'New Stop',
    'ns': 'Defined GT',
    'gt': 'Optimal GT',
  } }
  withSorting
  withFiltering/>;

export default ResultsTable;
