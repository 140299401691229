import React, { Component } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { ConfirmationModal, withLoader, withModals, withNotifications } from '@roamingaudit/react-ui-utils';

import axios from '../../../services/axios';
import TabSelector from '../../../components/TabSelector/TabSelector';
import ConfigurationsTable from './ConfigurationsTable/ConfigurationsTable';
import ConfigurationFilesModal from './ConfigurationFilesModal/ConfigurationFilesModal';
import ConfigurationUploadModal from './ConfigurationUploadModal/ConfigurationUploadModal';
import BulkUploadBriefModal from './BulkUploadBriefModal/BulkUploadBriefModal';
import ProgressService from '../../../services/progressService';
import BulkUpdateBriefModal from './BulkUpdateBriefModal/BulkUpdateBriefModal';
import UpdateImsiModal from './UpdateImsiModal/UpdateImsiModal';
import TopPanel from './TopPanel/TopPanel';
import NewConfigurationModal from './NewConfigurationModal/NewConfigurationModal';
import BulkUploadModal from './BulkUploadModal/BulkUploadModal';
import BulkUpdateModal from './BulkUpdateModal/BulkUpdateModal';
import ReviewModal from './ReviewModal/ReviewModal';

class ConfigurationView extends Component {
  state = {
    elements: {},
    columns: [],
    currentTab: null,
    selectedForRemoval: null
  };

  progressService;

  constructor(props) {
    super(props);
    this.progressService = new ProgressService('config-upload', this.props.updateProgress);
  }

  componentDidMount() {
    this.fetchNetworkElements();
  }

  async fetchNetworkElements() {
    this.props.showLoader();

    this.setState({ elements: [] });

    try {
      const networkElements = await this.loadNetworkElements();
      const currentTab = this.getNewCurrentTab(networkElements);

      this.setState({ ...networkElements, currentTab });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async loadNetworkElements() {
    const response = await axios.get('/configuration/elements');

    return response.data;
  }

  getNewCurrentTab(networkElements) {
    const typeIds = Object.keys(networkElements.elements);

    return typeIds.length > 0
      ? {
        id: typeIds[0],
        name: networkElements.elements[typeIds[0]].name
      } : null;
  }

  tabClickHandler(currentTab) {
    this.setState({ currentTab });
  }

  async removeNetworkElement(rowId) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.delete(`/configuration/${ rowId }`);

      const networkElements = await this.loadNetworkElements();
      const currentTab = networkElements[this.state.currentTab.id]
        ? this.state.currentTab : this.getNewCurrentTab(networkElements);

      this.setState({ ...networkElements, currentTab });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async startConfigurationUpload(row) {
    this.props.showLoader();

    try {
      const response = await axios.get(`/configuration/files/element/${ row.elementId }/files`);

      this.props.showModal(
        ConfigurationUploadModal,
        {
          entry: row,
          existingFiles: response.data.existingFiles,
          requiredFiles: response.data.requiredFiles,
          submitActionHandler: this.uploadConfiguration.bind(this)
        }
      );
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async listConfigurations(row) {
    this.props.showLoader();

    try {
      const response = await axios.get(`/configuration/files/${ row.id }`);

      this.props.showModal(ConfigurationFilesModal, {
        rows: response.data.rows,
        columns: response.data.columns
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async startUpdatingImsiConfiguration(id) {
    this.props.showLoader();

    try {
      const response = await axios.get(`/configuration/editing/${ id }/brief`);

      this.props.showModal(UpdateImsiModal, {
        checkboxesHandler: this.handleCheckboxes.bind(this),
        analyzeActionHandler: null,
        submitActionHandler: this.removeImsiEntries.bind(this),
        reviewDetailsHandler: this.reviewDetailsHandler.bind(this),
        entry: response.data,
        isRemovalAllowed: this.state.selectedForRemoval && this.state.selectedForRemoval.length > 0,
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async addNewConfiguration(vendor, type, subtype, names) {
    this.props.showLoader();
    this.props.hideModal();

    try {
      await axios.post('/configuration', {
        vendor: vendor.id, type: type.id, subtype: subtype.id, names
      });

      const networkElements = await this.loadNetworkElements();

      this.setState({ ...networkElements, currentTab: type });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async uploadConfiguration(entry, data) {
    this.props.showLoader({ progress: 0 });
    this.props.hideModal();

    try {
      const formData = new FormData();

      Object.entries(data).map(([ key, val ]) => {
        formData.append(key, val);
      });

      formData.append('time', Date.now());

      await this.progressService.run({
        url: '/configuration/files/' + entry.id,
        method: 'post',
        data: formData
      }, entry.id);

      const networkElements = await this.loadNetworkElements();

      this.setState({
        ...networkElements,
        currentTab: {
          id: entry.typeId,
          name: entry.typeName
        },
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  handleCheckboxes(selectedForRemoval) {
    this.setState({ selectedForRemoval });
  }

  async removeImsiEntries(entry) {
    this.props.showLoader();

    try {
      await axios.put(
        `/configuration/editing/${ entry.configurationId }/by-quantity/remove`,
        { qty: this.state.selectedForRemoval }
      );

      await this.startUpdatingImsiConfiguration(entry.configurationId);
      const networkElements = await this.loadNetworkElements();

      this.setState({

        ...networkElements,
        currentTab: {
          id: entry.type.id,
          name: entry.type.name
        },
        selectedForRemoval: null,
      });
    } catch (e) {
      this.props.processError(e);

      this.props.hideModal();

      this.setState({ selectedForRemoval: null });
    }

    this.props.hideLoader();
  }

  async reviewDetailsHandler(entry, valueLength) {
    this.props.showLoader();

    try {
      const response = await axios.get(`/configuration/editing/${ entry.configurationId }/review/${ valueLength }`);

      this.props.showModal(ReviewModal, {
        data: response.data,
        networkElement: entry.networkElement,
        valueLength
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async bulkRemove(query) {
    this.props.showLoader();

    try {
      await axios.put(
        `/configuration/editing/bulk/remove`,
        {
          ...query,
          configurationIds: this.state.selectedForRemoval
        }
      );

      await this.continueBulkUpdate(query);

      const networkElements = await this.loadNetworkElements();

      this.setState({

        ...networkElements,
        selectedForRemoval: null,
      });
    } catch (e) {
      this.props.processError(e);

      this.props.hideModal();
      this.setState({ selectedForRemoval: null });
    }

    this.props.hideLoader();
  }

  async reviewBulkDetailsHandler(row, query) {
    this.props.showLoader();

    const valueLength = row.valueLength || null;

    try {
      const response = await axios.put(`/configuration/editing/bulk/review`, {
        ...query,
        valueLength,
        summaryId: row.summaryId,
        elementId: row.elementId
      });

      this.props.showModal(ReviewModal, {
        data: response.data,
        networkElement: row.name,
        valueLength,
        query
      });
    } catch (e) {
      this.props.processError(e);

      this.props.hideModal();
    }

    this.props.hideLoader();
  }

  async continueBulkUpdate(query) {
    this.props.showLoader();

    try {
      const response = await axios.put('/configuration/editing/bulk/brief', query);

      this.props.hideModal();
      this.props.showModal(BulkUpdateBriefModal, {
        submitActionHandler: this.bulkRemove.bind(this),
        checkboxesHandler: this.handleCheckboxes.bind(this),
        query,
        columns: response.data.columns,
        subtypeName: response.data.subtypeName,
        rows: response.data.rows,
        reviewBulkDetails: this.reviewBulkDetailsHandler.bind(this)
      });
    } catch (e) {
      this.props.processError(e);

      this.props.hideModal();
    }

    this.props.hideLoader();
  }

  async continueBulkUpload(vendorId, typeId, subtypeId, file) {
    this.props.showLoader();

    try {
      const formData = new FormData();
      formData.append('bulkUpload', file);
      formData.append('time', Date.now());

      const response = await axios.post(
        `/configuration/files/${ vendorId }/${ typeId }/${ subtypeId }/bulk`,
        formData
      );

      this.props.hideModal();
      this.props.showModal(BulkUploadBriefModal, {
        data: response.data,
        submitActionHandler: this.finishBulkUpload.bind(this)
      });
    } catch (e) {
      this.props.processError(e);

      this.props.hideModal();
    }

    this.props.hideLoader();
  }

  async finishBulkUpload(bulkUploadBrief, createUnknown = false) {
    this.props.showLoader({ progress: 0 });
    this.props.hideModal();

    try {
      await this.progressService.run({
        url: `/configuration/files/${ bulkUploadBrief.elementId }/bulk/apply`,
        method: 'put',
        data: { time: bulkUploadBrief.time, createUnknown }
      }, `${ bulkUploadBrief.elementId }-bulk`);
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  render() {
    const topPanel = <TopPanel
      onNewNetworkElement={ () => this.props.showModal(
        NewConfigurationModal,
        { submitActionHandler: this.addNewConfiguration.bind(this) }
      ) }
      onBulkUpload={ () => this.props.showModal(
        BulkUploadModal,
        { submitActionHandler: this.continueBulkUpload.bind(this) }
      ) }
      onBulkUpdate={ () => this.props.showModal(
        BulkUpdateModal,
        { submitActionHandler: this.continueBulkUpdate.bind(this) }
      ) }/>;

    if (Object.keys(this.state.elements).length === 0) {
      return <>
          <Row>
            <Col xs="12">
              <Alert variant="warning">
                No Network Elements were found.
              </Alert>
            </Col>
          </Row>
          { topPanel }
        </>;
    }

    return <>
      { topPanel }
      <TabSelector
        current={ this.state.currentTab }
        clicked={ this.tabClickHandler.bind(this) }
        dictionary={ this.state.elements }/>
      <Row>
        <Col xs="12">
          <ConfigurationsTable
            updateImsiConfiguration={ this.startUpdatingImsiConfiguration.bind(this) }
            listConfigurations={ this.listConfigurations.bind(this) }
            removeNetworkElement={ row => this.props.showModal(ConfirmationModal, {
              data: row.id,
              question: 'Do you want to remove this Network Element?',
              onYes: this.removeNetworkElement.bind(this)
            }) }
            uploadConfiguration={ this.startConfigurationUpload.bind(this) }
            rows={ this.state.elements[this.state.currentTab.id].configurations }
            columns={ this.state.columns }/>
        </Col>
      </Row>
    </>;
  }
}
export default withLoader(withNotifications(withModals(ConfigurationView)));
