import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { withLoader, withModals, withNotifications } from '@roamingaudit/react-ui-utils';

import axios from '../../../services/axios';
import ProgressService from '../../../services/progressService';
import ElementSelector from '../../../components/ElementSelector/ElementSelector';
import ConfigurationsTable from './ConfigurationsTable/ConfigurationsTable';
import Key2RoamModal, { Key2RoamSupportedSubtypeEnum } from './Key2RoamModal/Key2RoamModal';
import ExtraInfoModal from './ExtraInfoModal/ExtraInfoModal';
import ElementTotals from '../../../components/ElementSelector/ElementTotals/ElementTotals';

import classes from './RunAudit.module.css';
import SdmRoamschmModal from './SdmRoamschmvlrModal/SdmRoamschmModal'

class RunAuditView extends Component {
  state = {
    details: null,
    selectedForAudit: [],
    currentVendor: null,
    currentType: null,
    currentSubtype: null,
  };

  progressService;

  constructor(props) {
    super(props);
    this.progressService = new ProgressService('audit', this.props.updateProgress);
  }

  async onTabChange(currentVendor, currentType, currentSubtype) {
    this.setState({ currentVendor, currentType, currentSubtype });

    this.props.showLoader();

    try {
      const response = await axios.get(
        '/audit/details/' + [ currentVendor.id, currentType.id, currentSubtype.id ].join('/')
      );

      this.setState({ details: response.data, selectedForAudit: [], areAllChecked: false });
    } catch (e) {
      this.props.processError(e);
      this.setState({ selectedForAudit: [], areAllChecked: false });
    }

    this.props.hideLoader();
  }

  checkboxesHandler(selectedForAudit) {
    this.setState({ selectedForAudit });
  }

  getAuditable() {
    return this.state.details.networkElements.filter(config => config.entries > 0);
  }

  initiateAudit() {
    if (this.state.details && this.state.details.extraInfo) {
      const payload = this.state.selectedForAudit.length === 1 ? this.state.details.rows[0].extraInfo : {};
      this.props.showModal(ExtraInfoModal, {
        onConfirm: (extra_info) => {
          this.props.hideModal();
          this.runAudit({ extra_info })
        },
        extraInfo: this.state.details.extraInfo,
        payload
      });

      return;
    }

    if (this.state.details && this.state.currentSubtype && Key2RoamSupportedSubtypeEnum[this.state.currentSubtype.name]) {
      this.props.showModal(Key2RoamModal, {
        variation: Key2RoamSupportedSubtypeEnum[this.state.currentSubtype.name],
        hasKey2Roam: this.state.details.hasKey2Roam,
        onConfirm: this.runAuditWithProcessing.bind(this)
      });

      return;
    }

    if (this.state.currentType.name === 'SDM' && this.state.currentSubtype.name.indexOf('ROAMSCHM') === 0) {
      const schemes = this.state.details.rows.reduce((acc, row) => {
        return this.state.selectedForAudit.indexOf(row.id) !== -1 ? acc.concat(row.extraInfo.schemes) : acc
      }, []).filter((v, i, a) => a.indexOf(v) === i)
      const agreements = this.state.details.rows.reduce((acc, row) => {
        return this.state.selectedForAudit.indexOf(row.id) !== -1 ? acc.concat(row.extraInfo.agreements) : acc
      }, []).filter((v, i, a) => a.indexOf(v) === i).map(agreement => ({id: agreement, name: agreement}))
      const flags = this.state.details.rows.reduce((acc, row) => {
        return this.state.selectedForAudit.indexOf(row.id) !== -1 ? acc.concat(row.extraInfo.flags) : acc
      }, []).reduce((acc, flag) => {
        if (!acc[flag.id]) {
          acc[flag.id] = flag
        }

        return acc
      }, {})

      this.props.showModal(SdmRoamschmModal, {
        availableAgreements: agreements,
        availableSchemes: schemes,
        availableFlags: Object.values(flags),
        onConfirm: this.runAuditWithProcessing.bind(this)
      });

      return;
    }

    this.runAudit(null);
  }

  runAuditWithProcessing(type, action, value) {
    this.props.hideModal();

    this.runAudit({ processing: { type, action, value } });
  }

  async runAudit(extraInfo) {
    this.props.showLoader({ progress: 0 });

    try {
      await this.progressService.runChunks({
        url: '/audit',
        method: 'put',
        data: {
          vendor_id: this.state.currentVendor.id,
          type_id: this.state.currentType.id,
          subtype_id: this.state.currentSubtype.id,
          network_elements: this.state.selectedForAudit,
          ...extraInfo
        }
      }, 'network_elements');

      this.props.history.push({
        pathname: [
          '/audit/results',
          this.state.currentVendor.id,
          this.state.currentType.id,
          this.state.currentSubtype.id
        ].join('/')
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();

    window.scrollTo(0, 0);
  }

  render() {
    return <>
      <Row>
        <Col xs="12">
          <ElementSelector
            elementChanged={ this.onTabChange.bind(this) }
            elementsUrl="/elements/available"
            firstName="subtype"
            secondName="type"
            thirdName="vendor"
            totals={
              this.state.details
                ? <ElementTotals
                  vendorId={ this.state.currentVendor.id }
                  typeId={ this.state.currentType.id }
                  subtypeId={ this.state.currentSubtype.id }
                  stats={ this.state.details.stats }/>
                : null
            }/>
        </Col>
      </Row>
      {
        this.state.details
          ? <>
            <Row>
              <Col xs="12">
                <ConfigurationsTable
                  checkboxesHandler={ this.checkboxesHandler.bind(this) }
                  columns={ this.state.details.columns }
                  rows={ this.state.details.rows }/>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className={ classes.buttonContainer }>
                <Button
                  variant="primary"
                  id="RunAudit__run-button"
                  disabled={ this.state.selectedForAudit.length === 0 }
                  onClick={ this.initiateAudit.bind(this) }>
                  Run Audit
                </Button>
              </Col>
            </Row>
          </>
          : null
      }
    </>;
  }
}

export default withLoader(withNotifications(withModals(withRouter(RunAuditView))));
