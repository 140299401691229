import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { withLoader, withModals, withNotifications } from '@roamingaudit/react-ui-utils';

import ElementSelector from '../../../components/ElementSelector/ElementSelector';
import SummariesTable from './SummariesTable/SummariesTable';
import axios from '../../../services/axios';
import TabSelector from '../../../components/TabSelector/TabSelector';
import ResultDetailComparisonModal from './ResultDetailComparisonModal/ResultDetailComparisonModal';
import ResultsExportModal from './ResultsExportModal/ResultsExportModal';
import SccpCheckModal from './SccpCheckModal/SccpCheckModal';
import AddExceptionModal from './AddExceptionModal/AddExceptionModal';
import RemoveExceptionModal from './RemoveExceptionModal/RemoveExceptionModal';
import { ALL, BOTH } from './ExceptionRemovalModeEnum';
import AnalysisTable from './AnalysisTable/AnalysisTable';
import Header from '../../../components/TabSelector/Header/Header';
import RowAnalysis from '../../../components/RowAnalysis/RowAnalysis';

import classes from './ResultsView.module.css';

class ResultsView extends Component {
  initialState = {
    summaries: null,
    currentVendor: null,
    currentType: null,
    currentSubtype: null,
    details: null,
    currentDetailsTab: null,
    currentSummaryId: null,
    sccpInfo: null,
    exceptionRemovalCandidates: null,
    exceptionMatchingElements: null,
    detailsFiltered: [],
    checkWithAnalysis: false,
    showCheckForRow: null
  };

  state = {
    ...this.initialState
  };

  fetchSummaries(currentVendor, currentType, currentSubtype) {
    return axios.get(
      '/audit/results/'
      + [ currentVendor.id, currentType.id, currentSubtype.id ].join('/')
      + '/summary'
    );
  }

  async onSummaryTabChange(currentVendor, currentType, currentSubtype) {
    this.props.showLoader();

    this.props.history.push('/' + [ 'audit', 'results', currentVendor.id, currentType.id, currentSubtype.id ].join('/'));
    this.setState({ ...this.initialState, currentVendor, currentType, currentSubtype });

    try {
      const response = await this.fetchSummaries(currentVendor, currentType, currentSubtype);

      this.setState({ summaries: response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  onDetailsTabChange(tab) {
    this.setState({ currentDetailsTab: tab })
  }

  async fetchAuditResultDetailComparison(analysisEntry) {
    this.props.showLoader();

    try {
      const response = await axios.get(
        '/audit/results/'
        + [ this.state.currentVendor.id, this.state.currentType.id, this.state.currentSubtype.id ].join('/')
        + '/entry/' + analysisEntry.id + '/details')


      this.props.showModal(ResultDetailComparisonModal, { data: response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  fetchDetails(summaryId) {
    return axios.get(
      '/audit/results/'
      + [ this.state.currentVendor.id, this.state.currentType.id, this.state.currentSubtype.id ].join('/')
      + '/summary/' + summaryId + '/details')
      .then(response => {
        response.data.tabs = Object.entries(response.data.tabs)
          .map(([ name, id ]) => ({ name, id }));

        return response;
      });
  }

  async openDetails(row) {
    this.props.showLoader();

    this.setState({ currentSummaryId: row.summaryId, currentDetailsTab: null, details: null });

    try {
      const response = await this.fetchDetails(row.summaryId);
      let currentDetailsTab = response.data.tabs[0];

      this.setState({ details: response.data, currentDetailsTab });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  filterHandler(detailsFiltered) {
    detailsFiltered.sort();
    const current = JSON.stringify([ ...this.state.detailsFiltered ]);

    if (JSON.stringify(detailsFiltered) !== current) {
      this.setState({ detailsFiltered });
    }
  }

  async doExport(summaries, resultTypes, combineToOneFile) {
    this.props.showLoader();

    this.props.hideModal();

    const elementPath = [ this.state.currentVendor.id, this.state.currentType.id, this.state.currentSubtype.id ].join('/');
    try {
      const response = await axios.put(
        '/audit/results/' + elementPath + '/export',
        { summaries, resultTypes, combineToOneFile }
      );

      window.open('/audit/results/' + elementPath + '/export/' + response.data.filename, '_blank');
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  getCurrentNeSignature() {
    return [ this.state.currentVendor.name, this.state.currentType.name, this.state.currentSubtype.name ]
      .map(name => name.replace('_', '')).join('_').toLowerCase();
  }

  showExport() {
    if (!this.state.summaries) {
      return;
    }

    this.props.showModal(ResultsExportModal, {
      vendorName: this.state.currentVendor.name,
      typeName: this.state.currentType.name,
      subtypeName: this.state.currentSubtype.name,
      resultTypes: this.state.summaries.resultTypes,
      networkElements: this.state.summaries.data,
      submitActionHandler: this.doExport.bind(this)
    });
  }

  async addException(row) {
    try {
      const response = await axios.get('/audit/results/'
        + this.state.currentVendor.id
        + "/" + this.state.currentType.id
        + "/" + this.state.currentSubtype.id
        + "/exception"
      );

      this.props.showModal(AddExceptionModal, {
        matchingNetworkElements: response.data,
        submitActionHandler: this.doAddException.bind(this, row.id)
      });
    } catch (e) {
      this.props.processError(e);
    }
  }

  async doAddException(exceptionCandidateId, addAllSimilar = false) {
    await this.modifyResultset(() => axios.put('/audit/results/'
      + this.state.currentVendor.id
      + '/' + this.state.currentType.id
      + '/' + this.state.currentSubtype.id
      + '/exception/' + exceptionCandidateId + (addAllSimilar ? '/global' : '')
    ));
  }

  operatorDetails(row) {
    window.open('/ir21/' + row.ir21_id, '_blank');
  }

  async removeException(row) {
    try {
      const response = await axios.get(
        '/audit/results/'
        + this.state.currentVendor.id
        + '/' + this.state.currentType.id
        + '/' + this.state.currentSubtype.id
        + "/exception/" + row.id);

      this.props.showModal(RemoveExceptionModal, {
        isGlobal: response.data.isGlobal,
        currentExceptions: response.data.exceptions,
        submitActionHandler: this.doRemoveException.bind(this, row.id)
      });
    } catch (e) {
      this.props.processError(e);
    }
  }

  async doRemoveException(entryId, type) {
    let suffix;
    switch (type) {
      case BOTH:
        suffix = '/both';
        break;
      case ALL:
        suffix = '/global';
        break;
      default:
        suffix = '';
        break;
    }

    await this.modifyResultset(() => axios.delete('/audit/results/'
      + this.state.currentVendor.id
      + "/" + this.state.currentType.id
      + "/" + this.state.currentSubtype.id
      + "/exception/" + entryId + suffix
    ));
  }

  async sccpCheck(row) {
    this.props.showLoader();

    try {
      const response = await axios.get('/audit/results/ir21/' + row.ir21_id + '/sccp');

      this.props.showModal(SccpCheckModal, { data: response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async approveRows(ids, isManual) {
    await this.modifyResultset(() => axios.put('/audit/results/'
      + this.state.currentVendor.id
      + '/' + this.state.currentType.id
      + '/' + this.state.currentSubtype.id
      + '/approve' + (isManual ? '/manual' : ''),
      { ids }
    ));
  }

  approveRow(row, isManual) {
    this.approveRows([ row.id ], isManual);
  }

  async declineRow(row, isManual) {
    await this.modifyResultset(() => axios.put('/audit/results/'
      + this.state.currentVendor.id
      + '/' + this.state.currentType.id
      + '/' + this.state.currentSubtype.id
      + '/decline/' + row.id + (isManual ? '/manual' : '')
    ));
  }

  async modifyResultset(modifierCallback, ...args) {
    this.props.showLoader();

    this.props.hideModal();

    try {
      await modifierCallback(...args);

      const summariesResponse = await this.fetchSummaries(
        this.state.currentVendor,
        this.state.currentType,
        this.state.currentSubtype
      );
      const detailsResponse = await this.fetchDetails(this.state.currentSummaryId);
      this.setState({ summaries: summariesResponse.data, details: detailsResponse.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  showCheckForRow(row, withAnalysis = false) {
    this.setState({ showCheckForRow: row, checkWithAnalysis: withAnalysis })
  }

  approveAll(isManual) {
    this.approveRows(this.state.detailsFiltered, isManual);
  }

  onHideAnalysis() {
    this.setState({ showCheckForRow: null, checkWithAnalysis: false });
  }

  render() {
    const refs = {};
    if (this.state.details) {
      Object.entries(this.state.details.data).forEach(([ tabId, data ]) => {
        refs[tabId] = data.length;
      });
    }

    let auditResultsSummaryHeader = null;
    if (this.state.summaries && Array.isArray(this.state.summaries.data)) {
      for (let i = 0; i < this.state.summaries.data.length; i++) {
        if (this.state.summaries.data[i].nonZeroResults) {
          auditResultsSummaryHeader = <Header>
            <div/>
            <Button variant="success" onClick={ this.showExport.bind(this) }>
              <FontAwesomeIcon icon={ faFileExcel }/> Export
            </Button>
          </Header>;
          break;
        }
      }
    }

    return (
      <div className={ classes.container }>
        <div id="ResultsView__summaries-container" className={ classes.summariesContainer }>
          <ElementSelector
            initThird={ this.props.match.params.vendor || null }
            initSecond={ this.props.match.params.type || null }
            initFirst={ this.props.match.params.subtype || null }
            elementChanged={ this.onSummaryTabChange.bind(this) }
            embedChildrenInline={ true }
            thirdName="vendor"
            secondName="type"
            firstName="subtype"
            elementsUrl="/elements/available">
            { auditResultsSummaryHeader }
          </ElementSelector>
          {
            this.state.summaries
              ? <SummariesTable
                highlight={ { field: 'summaryId', value: this.state.currentSummaryId } }
                onRowClick={ this.openDetails.bind(this) }
                columns={ this.state.summaries.columns }
                rows={ this.state.summaries.data }/>
              : null
          }
        </div>
        {
          this.state.details
            ? <div id="ResultsView__analysis-container">
              <TabSelector
                disabled={ false }
                refs={ refs }
                dictionary={ this.state.details.tabs }
                clicked={ this.onDetailsTabChange.bind(this) }
                current={ this.state.currentDetailsTab }>
                {
                  <Header>
                    <div>Filtered: { this.state.detailsFiltered.length }</div>
                    {
                      -1 !== [ 'WRONG_EPC', 'MISSING', 'FAILED', 'MANUAL' ].indexOf(this.state.currentDetailsTab.name)
                      && this.state.details.isApprovable
                        ? <div>
                          <Button variant="success"
                                  onClick={ () => this.approveAll(this.state.currentDetailsTab.name === 'MANUAL') }>
                            <FontAwesomeIcon icon={ faThumbsUp }/>
                          </Button>
                        </div>
                        : null
                    }
                  </Header>
                }
              </TabSelector>
              <AnalysisTable
                addException={ this.addException.bind(this) }
                removeException={ this.removeException.bind(this) }
                operatorDetails={ this.operatorDetails.bind(this) }
                showCheckForRow={ this.showCheckForRow.bind(this) }
                sccpCheck={ this.sccpCheck.bind(this) }
                approveRow={ this.approveRow.bind(this) }
                declineRow={ this.declineRow.bind(this) }
                key={ this.state.currentDetailsTab.id }
                onRowClick={ this.state.summaries.usesTemplate ? this.fetchAuditResultDetailComparison.bind(this) : null }
                onFilter={ this.filterHandler.bind(this) }
                columns={ this.state.details.columns }
                isApprovable={ this.state.details.isApprovable }
                isSccp={ this.state.currentSubtype.name.indexOf('SCCP_ROUTING_CASE_NP') === 0 }
                tab={ this.state.currentDetailsTab }
                rows={ this.state.details.data[this.state.currentDetailsTab.id] || [] }/>
            </div>
            : null
        }
        {
          this.state.showCheckForRow
            ? <RowAnalysis
              onHideCallback={ this.onHideAnalysis.bind(this) }
              row={ this.state.showCheckForRow }
              showFullAnalysis={ this.state.checkWithAnalysis }
              url={ `/audit/results/${ this.state.currentVendor.id }/${ this.state.currentType.id }/${ this.state.currentSubtype.id }/analyze/${ this.state.currentSummaryId }` }/>
            : null
        }
      </div>
    );
  }
}

export default withLoader(withNotifications(withModals(withRouter(ResultsView))));
