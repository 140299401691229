import React  from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@roamingaudit/react-ui-utils';

import ManualCheck from '../ManualCheck/ManualCheck';

const ManualCheckModal = props => {
  return <Modal
    size="lg"
    hideModal={ () => props.hideModal() }
    title="Network Elements Information">
    <ManualCheck checkInfo={ props.checkInfo } entry={ props.entry }/>
  </Modal>;
};

ManualCheckModal.propTypes = {
  entry: PropTypes.object.isRequired,
  checkInfo: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ManualCheckModal;
