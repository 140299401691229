import React, { createRef } from 'react';
import { Button, Col, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Modal, ButtonSpinner } from '@roamingaudit/react-ui-utils';

import classes from './BulkUpdateModal.module.css';
import TagsInput from '../../../../components/TagsInput/TagsInput';

const BulkUpdateModal = props => {
  const form = createRef();
  const formik = useFormik({
    initialValues: {
      subtype: null,
      values: [],
      mode: 'keep',
      lengths: []
    },
    onSubmit: props.submitActionHandler
  });

  return <Modal
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
        <Button variant="primary"
                type="submit"
                disabled={
                  formik.isSubmitting || !formik.dirty || !formik.isValid
                  || (formik.values.lengths.length === 0 && formik.values.values.length === 0)
                }
                form="bulk-network-elements-update-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Next
        </Button>
      </>
    }
    hideModal={ props.hideModal }
    title={ formik.values.subtype ? 'Bulk Update ' + formik.values.subtype : 'Choose subtype' }>
    <Form
      noValidate
      id="bulk-network-elements-update-form"
      ref={ form }
      onSubmit={ formik.handleSubmit }>
      <Form.Row>
        <Form.Group
          className={ classes.subtypeSelectorContainer }
          as={ Col }>
          <ToggleButtonGroup
            type="radio"
            name="subtype"
            onChange={ val => {
              formik.setFieldValue('values', []);
              formik.setFieldValue('subtype', val);
            } }>
            <ToggleButton value="IMSI">IMSI</ToggleButton>
            <ToggleButton value="ROAMING AREA">ROAMING AREA</ToggleButton>
          </ToggleButtonGroup>
        </Form.Group>
      </Form.Row>
      {
        [ 'IMSI', 'ROAMING AREA' ].indexOf(formik.values.subtype) !== -1
          ? <>
            <Form.Row>
              <Form.Group as={ Col }>
                <Form.Label>
                  { formik.values.subtype === 'IMSI' ? 'IMSI Value (Starts With)' : 'CHARS' }
                </Form.Label>
                <TagsInput
                  onlyUnique={ true }
                  validationRegex={ formik.values.subtype === 'IMSI' ? /^[0-9]+$/ : /^[0-9a-z]+$/i }
                  placeholder={ formik.values.subtype === 'IMSI' ? 'IMSI' : 'CHARS' }
                  disabled={ formik.values.lengths.length > 0 }
                  value={ formik.values.values }
                  onChange={ tags => formik.setFieldValue('values', tags) }/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={ Col }>
                <h5>Select audits to export:</h5>
                <Form.Check>
                  <Form.Check.Label>
                    <Form.Check.Input
                      type="radio"
                      name="mode"
                      value="keep"
                      checked={ formik.values.mode === 'keep' }
                      onChange={ formik.handleChange }/>
                    Keep (Remove Everything Else)
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={ Col }>
                <Form.Check>
                  <Form.Check.Label>
                    <Form.Check.Input
                      type="radio"
                      name="mode"
                      value="remove"
                      checked={ formik.values.mode === 'remove' }
                      onChange={ formik.handleChange }/>
                    Remove
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Form.Row>
            {
              'IMSI' === formik.values.subtype
                ? <>
                  <Row className={ classes.separatorRow }>
                    <Col className={ classes.separatorWrapper }>
                      <div className={ classes.separator }/>
                    </Col>
                    <Col xs="auto">OR</Col>
                    <Col className={ classes.separatorWrapper }>
                      <div className={ classes.separator }/>
                    </Col>
                  </Row>
                  <Form.Row>
                    <Form.Group as={ Col }>
                      <Form.Label>IMSI Length</Form.Label>
                      <TagsInput
                        validationRegex={ /^[0-9]+$/ }
                        onlyUnique={ true }
                        placeholder="IMSI Length"
                        disabled={ formik.values.values.length > 0 }
                        value={ formik.values.lengths }
                        onChange={ tags => formik.setFieldValue('lengths', tags) }/>
                    </Form.Group>
                  </Form.Row>
                </>
                : null
            }
          </>
          : null
      }
    </Form>
  </Modal>;
};

BulkUpdateModal.propTypes = {
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default BulkUpdateModal;
