import React  from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@roamingaudit/react-ui-utils';

import classes from './BlockedFeatureModal.module.css';

const BlockedFeatureModal = props => <Modal
  title={ <div className={ classes.title }>The { props.featureName } feature is Deactivated </div> }
  hideModal={ props.hideModal }>
  <div>
    <img className={ classes.featureImage }
         src={ '/react/images/features/' + props.featureGroup + '.png' }
         alt={ props.featureGroup + ' features' }/>
  </div>
  <div className={ classes.readMoreLinkWrapper }>
    <a className={ classes.readMoreLink } href={ 'https://roamingaudit.com/' + props.featureGroup + '-management/' }
       target="_blank">
      Read more about the feature here.
    </a>
  </div>
</Modal>;

BlockedFeatureModal.propTypes = {
  featureGroup: PropTypes.oneOf([ 'configuration', 'ir21', 'coordination', 'partner', 'security' ]).isRequired,
  featureBlockedUrl: PropTypes.string.isRequired,
  featureName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default BlockedFeatureModal;
