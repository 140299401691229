import { LiteTable } from '@roamingaudit/react-lite-table';
import React from 'react';

const CheckTable = props => <LiteTable
  rows={ props.rows }
  onFilter={ props.onFilter }
  columns={ props.columns }
  initialFiltering={ props.filters  }
  withFiltering/>

export default CheckTable;
