import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form } from 'react-bootstrap';
import { useFormik } from 'formik';

import classes from './ConfigurationUploadModal.module.css';
import { ButtonSpinner, Modal } from '@roamingaudit/react-ui-utils';

const ConfigurationUploadModal = props => {
  const [ isUsingExisting, useExisting ] = useState({});
  const [ uploadLabel, setUploadLabel ] = useState({});

  const form = createRef();
  const formik = useFormik({
    initialValues: props.requiredFiles.reduce((acc, val) => ({ ...acc, [val]: null }), []),
    onSubmit: data => props.submitActionHandler(props.entry, data)
  });

  const doUseExisting = alias => {
    const isUsingExistingNew = !isUsingExisting[alias];
    useExisting({ ...isUsingExisting, [alias]: isUsingExistingNew });
    setUploadLabel({ ...uploadLabel, [alias]: null });

    formik.setFieldValue(alias, isUsingExistingNew ? props.existingFiles[alias][0].id : null);
  };

  return <Modal
    id="configuration__upload-modal"
    title={ 'Upload New Files for ' + props.entry.name }
    hideModal={ props.hideModal }
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>
          Close
        </Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting || !formik.dirty || !formik.isValid }
                form="upload-network-element-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Upload
        </Button>
      </>
    }>
    <Form
      noValidate
      id="upload-network-element-form"
      ref={ form }
      onSubmit={ formik.handleSubmit }>
      {
        props.requiredFiles.map(
          alias => <Form.Row>
            <Form.Group
              as={ Col }
              xs="10"
              controlId={ alias }>
              <Form.Label>{ alias }</Form.Label>
              {
                isUsingExisting[alias]
                  ? <Form.Control
                    as="select"
                    name={ alias }
                    id={ alias }
                    onChange={ formik.handleChange }
                    isInvalid={ !!formik.errors[alias] }
                    isValid={ formik.touched[alias] && !formik.errors[alias] }>
                    {
                      props.existingFiles[alias].map(
                        file => <option value={ file.id }>{ file.uploadedAt + ' ' + file.name }</option>
                      )
                    }
                  </Form.Control>
                  : <Form.File
                    name={ alias }
                    id={ alias }
                    onChange={ event => {
                      setUploadLabel({ ...uploadLabel, [alias]: event.currentTarget.files[0].name });
                      formik.setFieldValue(alias, event.currentTarget.files[0]);
                    } }
                    isInvalid={ !!formik.errors[alias] }
                    isValid={ formik.touched[alias] && !formik.errors[alias] }
                    label={
                      <span className={ classes.formFileLabel }>
                        { uploadLabel[alias] || 'Choose a ' + alias + ' file' }
                      </span>
                    }
                    custom/>
              }
              <Form.Control.Feedback type="invalid">
                { formik.errors[alias] }
              </Form.Control.Feedback>
            </Form.Group>
            <Col xs="2" className={ classes.useExistingSwitch }>
              {
                props.existingFiles[alias] && props.existingFiles[alias].length > 0
                  ? <Button
                    variant="link"
                    className={ classes.useExistingSwitchButton }
                    onClick={ () => doUseExisting(alias) }>
                    { isUsingExisting[alias] ? 'Upload New' : 'Use Existing' }
                  </Button>
                  : null
              }
            </Col>
          </Form.Row>
        )
      }
    </Form>
  </Modal>;
}

ConfigurationUploadModal.propTypes = {
  existingFiles: PropTypes.array.isRequired,
  requiredFiles: PropTypes.array.isRequired,
  hideModal: PropTypes.func.isRequired,
  entry: PropTypes.object.isRequired,
  submitActionHandler: PropTypes.func.isRequired
};

export default ConfigurationUploadModal;
