import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Modal } from '@roamingaudit/react-ui-utils';

import classes from './Key2RoamModal.module.css';

export class Key2RoamEnum {
  static NORMAL = 'NORMAL';
  static KEY2ROAM_EXCLUDE = 'KEY2ROAM_EXCLUDE';
  static KEY2ROAM_REPLACE = 'KEY2ROAM_REPLACE';
  static TADIG_EXCLUDE = 'TADIG_EXCLUDE';
}

export class Key2RoamSupportedSubtypeEnum {
  static VLIST_SGSN = 'VLIST_SGSN';
  static VLIST_PREPAID = 'VLIST_PREPAID';
  static VLIST_POSTPAID = 'VLIST_POSTPAID';
  static VLIST_SGSN_PREPAID = 'VLIST_SGSN_PREPAID';
  static PLMNTPL_LTE = 'PLMNTPL_LTE';
  static OUTBOUND_CAMEL = 'OUTBOUND_CAMEL';
  static ASN = 'ASN';
  static IP_RANGE = 'IP_RANGE';
  static GPRS_DNS = 'GPRS_DNS';
  static LTE_DNS = 'LTE_DNS';
}

class Key2RoamModal extends Component {
  state = {
    type: null,
    action: null,
    value: []
  };

  regexps = {
    ASN: /^[0-9]+$/,
    IP_RANGE: /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\/(?:1[6-9]+|2[0-9]|3[0-2])$/
  };

  isReplaceEnabled() {
    return this.props.variation.indexOf('VLIST_') !== 0
      && this.props.variation !== Key2RoamSupportedSubtypeEnum.OUTBOUND_CAMEL
      && this.props.variation !== Key2RoamSupportedSubtypeEnum.PLMNTPL_LTE;
  }

  processInput(event) {
    const content = event.target.value;
    const value = [];

    content.split('\n').forEach(line => {
      if (
        this.regexps[this.props.variation].test(line)
        && value.indexOf(line) === -1
      ) {
        value.push(line);
      }
    });

    if (JSON.stringify(value) !== JSON.stringify(this.state.value)) {
      this.setState({ value });
    }
  }

  processTadigsInput(event) {
    const content = event.target.value;
    const value = [];

    content.split('\n').forEach(line => {
      if (
        /^[0-9a-z]{5}$/i.test(line)
        && value.indexOf(line) === -1
      ) {
        value.push(line);
      }
    });

    if (JSON.stringify(value) !== JSON.stringify(this.state.value)) {
      this.setState({ value });
    }
  }

  handleOptionChange(event) {
    let type, action
    if (event.target.value === Key2RoamEnum.NORMAL) {
      type = null
      action = null
    } else {
      [type, action] = event.target.value.split('_')
    }

    this.setState({ type, action, value: [] });
  }

  render() {
    return <Modal
      actions={
        <>
          <Button variant="secondary" onClick={ this.props.hideModal }>Close</Button>
          <Button variant="primary"
                  id="key2RoamRunAuditButton"
                  onClick={
                    () => this.props.onConfirm(this.state.type, this.state.action, this.state.value)
                  }>
            Run Audit
          </Button>
        </>
      }
      hideModal={ this.props.hideModal }
      title="Choose an option to continue">
      <div className={ 'Key2Roam__option ' + classes.option }>
        <label>
          <input type="radio" name="flow" onChange={ this.handleOptionChange.bind(this) }
                 value={ Key2RoamEnum.NORMAL } checked={ this.state.type === null }/>
          <span className={ classes.label }>Continue</span>
        </label>
      </div>
      {
        this.props.hasKey2Roam
          ? <>
            <div className={ 'Key2Roam__option ' + classes.option }>
              <label>
                <input type="radio" name="flow" onChange={ this.handleOptionChange.bind(this) }
                       value={ Key2RoamEnum.KEY2ROAM_EXCLUDE }
                       checked={ this.state.type === 'KEY2ROAM' && this.state.action === 'EXCLUDE' }/>
                <span className={ classes.label }>Exclude Key2Roam</span>
              </label>
            </div>
            {
              this.isReplaceEnabled()
                ? <div className={ 'Key2Roam__option ' + classes.option }>
                  <label>
                    <input type="radio" name="flow" onChange={ this.handleOptionChange.bind(this) }
                           value={ Key2RoamEnum.KEY2ROAM_REPLACE }
                           checked={ this.state.type === 'KEY2ROAM' && this.state.action === 'REPLACE' }/>
                    <span className={ classes.label }>Replace Key2Roam with a list of { this.props.variation }s</span>
                  </label>
                  {
                    this.state.type === 'KEY2ROAM' && this.state.action === 'REPLACE'
                      ? <>
                  <textarea onChange={ this.processInput.bind(this) }
                            className={ classes.textarea }/>
                        <div>{ this.state.value.length } Unique { this.props.variation }s</div>
                      </>
                      : null
                  }
                </div>
                : null
            }
          </>
          : null
      }
      <div className={ 'Key2Roam__option ' + classes.option }>
        <label>
          <input type="radio" name="flow" onChange={ this.handleOptionChange.bind(this) }
                 value={ Key2RoamEnum.TADIG_EXCLUDE }
                 checked={ this.state.type === 'TADIG' && this.state.action === 'EXCLUDE' }/>
          <span className={ classes.label }>Exclude list of TADIGs</span>
        </label>
        {
          this.state.type === 'TADIG' && this.state.action === 'EXCLUDE'
            ? <>
                  <textarea onChange={ this.processTadigsInput.bind(this) }
                            className={ classes.textarea }/>
              <div>{ this.state.value.length } Unique TADIGs</div>
            </>
            : null
        }
      </div>
    </Modal>
  }
}

Key2RoamModal.propTypes = {
  hasKey2Roam: PropTypes.bool,
  variation: PropTypes.objectOf(Key2RoamSupportedSubtypeEnum),
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
};

export default Key2RoamModal;
