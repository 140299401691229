import React  from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, Form } from 'react-bootstrap';
import { ButtonSpinner, Modal } from '@roamingaudit/react-ui-utils';

const NumberRangeOptimizerAuditModal = ({ countries, onConfirm, hideModal }) => {
  const schema = yup.object({
    country: yup.string()
      .oneOf(countries)
      .required('This field is a obligatory.'),
    userInput: yup.string().matches(
      /^(:?[0-9]+|"[0-9]+"),(:?[0-9]+|"[0-9]+")$/m,
      'Should contain only coma-separated numbers (possibly wrapped with double-quotes).')
      .required('This field is a obligatory.'),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      country: countries[0],
      userInput: '',
    },
    onSubmit: preferences => onConfirm(preferences.country, preferences.userInput)
  });

  return <Modal
    actions={
      <>
        <Button variant="secondary" onClick={ hideModal }>Close</Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting || !formik.dirty || !formik.isValid }
                form="nro-audit-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Download Audit
        </Button>
      </>
    }
    hideModal={ hideModal }
    title="Number Range Audit configuration">
    <Form
      noValidate
      id="nro-audit-form"
      onSubmit={ formik.handleSubmit }>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="12"
          controlId='form-country'>
          <Form.Label>Select a country</Form.Label>
          <Form.Control
            as="select"
            name="country"
            value={ formik.values.country }
            onChange={ formik.handleChange }>
            { countries.map(country => <option value={ country }>{ country }</option>) }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            { formik.errors.country }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="12"
          controlId='form-userInput'>
          <Form.Label>Insert data to audit</Form.Label>
          <Form.Control
            as="textarea"
            name="userInput"
            rows={ 5 }
            value={ formik.values.userInput }
            onChange={ formik.handleChange }
            isValid={ formik.touched.userInput && !formik.errors.userInput }
            isInvalid={ !!formik.errors.userInput && !formik.touched.userInput }
            placeholder='"01234",56789'/>
          <Form.Control.Feedback type="invalid">
            { formik.errors.userInput }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    </Form>
  </Modal>
}

NumberRangeOptimizerAuditModal.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string),
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
};

export default NumberRangeOptimizerAuditModal;
