import React from 'react';
import { Button } from 'react-bootstrap';

import classes from './Page.module.css';

const Page = ({ i, onChange, isDisabled = false }) => (
  <Button variant="outline-primary" onClick={ () => onChange(i) } className={ classes.page } disabled={ isDisabled }>{ i }</Button>
)

export default Page
