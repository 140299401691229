import React, { useCallback } from 'react'

import classes from './Multiselect.module.css'

const Multiselect = ({options, selected, onChange}) => {
  const toggleOption = useCallback(id => {
    let newSelected = [ ...selected ]
    const index = selected.indexOf(id)
    if (index !== -1) {
      delete newSelected[index]
      newSelected = newSelected.filter(_ => _)
    } else {
      newSelected.push(id)
    }

    onChange(newSelected)
  }, [onChange, selected])

  const clearAll = () => onChange([])

  const selectAll = useCallback(() => onChange(options.map(option => option.id)), [onChange, options])

  return <>
    <div className={classes.multiselect__container}>
      {
        options.map((option) => (
          <div
            key={ option.id }
            className={classes.multiselect__option + ' ' + (selected.indexOf(option.id) !== -1 ? classes['multiselect__option--selected'] : '') }
           onClick={() => toggleOption(option.id)}>
            { option.name }
      </div>
        ))
      }
    </div>
    <div className={classes['multiselect__bulk-switch']}
         onClick={ selected.length === options.length ? clearAll : selectAll}>
      { selected.length === options.length ? 'Clear All' : 'Select All' }
    </div>
  </>
}

export default Multiselect
